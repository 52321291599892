import React from 'react'
import {
  WidgetLabelContainer,
  WidgetRemoveContainer,
  WidgetTextOverrite,
} from '../widget-base-styles'
import { IconButton, Typography } from '@imbox/ui-components'
import {
  AggreagatedTimesWidgetContainer,
  AggreagatedTimesWidgetContentContainer,
  AggreagatedTimesWidgetInnerContainer,
  AggreagatedTimesWidgetTextOverrite,
} from './aggregated-widget-styles'
import { formatDuration } from '../../lib/format-duration'
import { useAppSelector } from '../../hooks/redux-hooks'

type Props = {
  firstData: number
  seccondData: number
  thirdData: number
  firstTitle: string
  seccondTitle: string
  thirdTitle: string
  label?: string
  isActive: boolean
  onRemove?: () => void
}

export const AggregatedTimesWidget = React.forwardRef<any, Props>(
  (
    {
      onRemove,
      firstData,
      seccondData,
      thirdData,
      firstTitle,
      seccondTitle,
      thirdTitle,
      label,
      isActive,
      children,
      ...props
    },
    ref
  ) => {
    const editMode = useAppSelector((state) => state.config.editMode)

    return (
      <AggreagatedTimesWidgetContainer editMode={editMode} isActive={isActive} ref={ref} {...props}>
        <AggreagatedTimesWidgetInnerContainer>
          {editMode && label && (
            <WidgetLabelContainer>
              <Typography type="BodySmallRegular">{label}</Typography>
            </WidgetLabelContainer>
          )}
          {onRemove && (
            <WidgetRemoveContainer>
              <IconButton size="small" onClick={onRemove} type="Secondary">
                close
              </IconButton>
            </WidgetRemoveContainer>
          )}
          <AggreagatedTimesWidgetContentContainer>
            <Typography type="Heading1">
              <AggreagatedTimesWidgetTextOverrite>
                {formatDuration(firstData)}
              </AggreagatedTimesWidgetTextOverrite>
            </Typography>

            <Typography type="BodyLargeSemiBold">
              <WidgetTextOverrite>{firstTitle}</WidgetTextOverrite>
            </Typography>
          </AggreagatedTimesWidgetContentContainer>
          <AggreagatedTimesWidgetContentContainer>
            <Typography type="Heading1">
              <AggreagatedTimesWidgetTextOverrite>
                {formatDuration(seccondData)}
              </AggreagatedTimesWidgetTextOverrite>
            </Typography>

            <Typography type="BodyLargeSemiBold">
              <WidgetTextOverrite>{seccondTitle}</WidgetTextOverrite>
            </Typography>
          </AggreagatedTimesWidgetContentContainer>

          <AggreagatedTimesWidgetContentContainer>
            <Typography type="Heading1">
              <AggreagatedTimesWidgetTextOverrite>
                {formatDuration(thirdData)}
              </AggreagatedTimesWidgetTextOverrite>
            </Typography>

            <Typography type="BodyLargeSemiBold">
              <WidgetTextOverrite>{thirdTitle}</WidgetTextOverrite>
            </Typography>
          </AggreagatedTimesWidgetContentContainer>
        </AggreagatedTimesWidgetInnerContainer>
      </AggreagatedTimesWidgetContainer>
    )
  }
)
