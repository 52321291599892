import { TableContainer, TableHeader, TableCell } from '@imbox/ui-components'
import styled, { css } from 'styled-components'
import { Theme } from '../../theme/theme'
import { widgetBaseStyle } from '../widget-base-styles'

export const TableWidgetContainer = styled.div<{
  overrideTheme?: keyof Theme['widgets']
  editMode?: boolean
  isActive: boolean
}>`
  ${widgetBaseStyle};
  position: relative;
  padding: 0;
`

export const WidgetTableContainer = styled(TableContainer)`
  height: 100%;
  padding-top: 0;
  overflow: visible;

  table {
    background-color: ${(props) => props.theme.genericWidget.plate};

    margin-left: 0;
    margin-right: 0;
    width: 100%;
    box-shadow: none;

    tbody {
      tr:hover {
        td > div {
          background-color: ${(props) => props.theme.genericWidget.plate};
        }
      }
    }

    td {
      height: 88px;

      ${(props) =>
        props.theme.widgets.callQueues.cellBorder &&
        css`
          border-bottom: 1px solid ${props.theme.widgets.callQueues.cellBorder};
        `}
    }

    th {
      ${(props) =>
        props.theme.widgets.callQueues.headerBorder &&
        css`
          border-bottom: 1px solid
            ${props.theme.widgets.callQueues.headerBorder};
        `}

      position: sticky;
      top: 0;
      background-color: ${(props) => props.theme.genericWidget.plate};
    }
  }
`

export const TableHeaderLarge = styled(TableHeader)`
  height: 72px;

  div {
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.widgets.callQueues.headerText};
  }
`

export const TableCellLarge = styled(TableCell)`
  div {
    font-weight: 500;
    font-size: 24px;
    line-height: normal;

    ${(props) =>
      props.theme.widgets.callQueues.cellText &&
      css`
        color: ${props.theme.widgets.callQueues.cellText};
      `}
  }

  ${(props) =>
    props.theme.widgets.callQueues.cellPlate &&
    css`
      background-color: ${props.theme.widgets.callQueues.cellPlate};
    `}

  ${(props) =>
    props.highEmphasis &&
    props.theme.widgets.callQueues.callPlateHighEmphasis &&
    css`
      background-color: ${props.theme.widgets.callQueues.callPlateHighEmphasis};
    `}
`
