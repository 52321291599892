import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../hooks/redux-hooks'
import {
  selectDataPointCallsIncoming,
  selectDataPointCallsWaiting,
  selectDataPointCallsOutgoing,
} from '../../lib/features/data-points/data-points-slice'
import { SplittedStatsWidget } from '../splitted-stats-widget/splitted-stats-widget'

type Props = {
  id: string
  isActive: boolean
  onRemove?: () => void
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export const CallsCurrentWorkloadWidget = React.forwardRef<any, Props>(
  ({ id, isActive, onRemove, children, ...props }, ref) => {
    const { t } = useTranslation()
    const incomingCalls = useAppSelector((state) =>
      selectDataPointCallsIncoming(state, id)
    )
    const waitingCalls = useAppSelector((state) =>
      selectDataPointCallsWaiting(state, id)
    )
    const outgoingCalls = useAppSelector((state) =>
      selectDataPointCallsOutgoing(state, id)
    )
    const label = useAppSelector(
      (state) => state.settings.widgetSettings[id]?.blockLabel
    )

    return (
      <SplittedStatsWidget
        onRemove={onRemove}
        topStat={waitingCalls}
        topText={t('callsCurrentWorkloadWidget.waitingCalls')}
        bottomLeftStat={incomingCalls}
        bottomLeftText={t('callsCurrentWorkloadWidget.incomingCalls')}
        bottomRightStat={outgoingCalls}
        bottomRightText={t('callsCurrentWorkloadWidget.outgoingCalls')}
        label={label}
        isActive={isActive}
        ref={ref}
        {...props}
      />
    )
  }
)
