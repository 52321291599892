import GridLayout from 'react-grid-layout'

export const predefinedMultiLayout: GridLayout.Layout[] = [
  {
    w: 1,
    h: 1,
    x: 5,
    y: 1,
    i: 'callsTotalCompletedWidget',
    isResizable: false,
  },
  {
    w: 1,
    h: 1,
    x: 4,
    y: 1,
    i: 'callsTotalAbandonedWidget',
    isResizable: false,
  },
  {
    w: 1,
    h: 1,
    x: 4,
    y: 0,
    i: 'callsTotalMissedWidget',
    isResizable: false,
  },
  {
    w: 1,
    h: 1,
    x: 5,
    y: 0,
    i: 'callsTotalAnsweredWidget',
    isResizable: false,
  },
  {
    w: 1,
    h: 1,
    x: 2,
    y: 0,
    i: 'ticketIncomingWidget',
    maxW: 2,
    maxH: 2,
    isResizable: true,
  },
  {
    w: 2,
    h: 1,
    x: 2,
    y: 1,
    i: 'ticketUnsolvedWidget',
    maxW: 2,
    maxH: 2,
    isResizable: true,
  },
  {
    w: 1,
    h: 1,
    x: 3,
    y: 0,
    i: 'ticketServedWidget',
    maxW: 2,
    maxH: 2,
    isResizable: true,
  },
  {
    w: 2,
    h: 2,
    x: 4,
    y: 2,
    i: 'callsCurrentWorkloadWidget',
  },
  {
    w: 1,
    h: 1,
    x: 1,
    y: 0,
    i: 'chatsAnsweredWidget',
    maxW: 2,
    maxH: 2,
    isResizable: true,
  },
  {
    w: 2,
    h: 1,
    x: 0,
    y: 1,
    i: 'chatsTotalWidget',
    maxW: 2,
    maxH: 2,
    isResizable: true,
  },
  {
    w: 1,
    h: 1,
    x: 0,
    y: 0,
    i: 'chatsMissedWidget',
    maxW: 2,
    maxH: 2,
    isResizable: true,
  },
  {
    w: 2,
    h: 2,
    x: 0,
    y: 2,
    i: 'chatCurrentWorkloadWidget',
  },
  {
    w: 4,
    h: 1,
    x: 2,
    y: 4,
    i: 'callQueuesWidget',
    minW: 4,
    minH: 1,
    isResizable: true,
  },
  {
    w: 2,
    h: 1,
    x: 0,
    y: 4,
    i: 'chatTableWidget',
    minW: 2,
    minH: 1,
    isResizable: true,
  },
  {
    w: 2,
    h: 2,
    x: 2,
    y: 2,
    i: 'callsTimesWidget',
  },
]
