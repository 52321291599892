import React from 'react'
import { ThemeProvider } from 'styled-components'
import { useAppSelector } from '../hooks/redux-hooks'
import { themes } from '../theme/theme'

export const ThemeController: React.FC = ({ children }) => {
  const theme = useAppSelector((state) => state.theme)

  return <ThemeProvider theme={themes[theme]}>{children}</ThemeProvider>
}
