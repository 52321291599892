import styled, { css } from 'styled-components'

export const Container = styled.div<{ isOpen: boolean }>`
  width: 300px;
  height: calc(100% - 64px);
  z-index: 1;
  background-color: ${(props) => props.theme.color.white};
  box-shadow: ${(props) => props.theme.shadow.mediumStroke};
  position: absolute;
  top: 64px;
  overflow-y: auto;
  padding: 24px 16px;
  transition: right 0.2s ease-out 0s;

  ${(props) =>
    props.isOpen
      ? css`
          right: 0;
        `
      : css`
          right: -301px;
        `}
`

export const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`

export const InnerContainer = styled.div``

export const InputContainer = styled.div`
  display: grid;
  grid-gap: 24px;
  margin-top: 24px;
`
