import React from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks'
import { useTranslation } from 'react-i18next'
import { TextInput } from '@imbox/ui-components'
import {
  setQueues,
  setTargetTime,
} from '../../lib/features/settings/settings-slice'
import { MultiSelect, ValueLabelPair } from '../multi-select/multi-select'
import { selectDataPointCallQueues } from '../../lib/features/data-points/data-points-slice'
import { useSetUpdated } from '../../hooks/use-set-updated'

export const CallPropertyPanel: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const widgetId = useAppSelector((state) => state.propertyPanel.id)
  const widgetType = useAppSelector((state) => state.propertyPanel.type)

  const _queues = useAppSelector(selectDataPointCallQueues)
  const selectedQueues = useAppSelector(
    (state) => state.settings.widgetSettings[widgetId]?.queues
  )
  const selectedTargetTime = useAppSelector(
    (state) => state.settings.widgetSettings[widgetId]?.targetTime ?? '60'
  )

  useSetUpdated([selectedQueues, selectedTargetTime], widgetId)

  const queues: ValueLabelPair[] = _queues.live.map((queue) => {
    return {
      value: queue.name,
      label: queue.name,
    }
  })

  const onQueueChange = (option: any) => {
    dispatch(
      setQueues({
        widgetId,
        queues: option,
      })
    )
  }

  const onTargetTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setTargetTime({
        widgetId,
        targetTime: event.target.value,
      })
    )
  }

  return (
    <>
      <MultiSelect
        id="queue"
        label={t('callsPropertyPanel.queuesLabel')}
        isMulti
        placeholder={t('callsPropertyPanel.queuesPlaceholder')}
        options={queues}
        value={selectedQueues}
        onChange={onQueueChange}
      />
      {widgetType === 'callServiceLevelWidget' && (
        <TextInput
          value={selectedTargetTime}
          label={t('callsPropertyPanel.slaTargetTimeLabel')}
          onChange={onTargetTimeChange}
          inputType={'number'}
          min="0"
        ></TextInput>
      )}
    </>
  )
}
