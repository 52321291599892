import { IconButton, Typography } from '@imbox/ui-components'
import { TypographyStyles } from '@imbox/ui-components/lib/typography/typography-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../hooks/redux-hooks'
import { selectDataPointTicketTypes } from '../../lib/features/data-points/data-points-slice'
import { AutoScroll } from '../auto-scroll/auto-scroll'
import {
  WidgetLabelContainer,
  WidgetRemoveContainer,
  WidgetTextOverrite,
} from '../widget-base-styles'
import {
  TicketsByChannelWidgetBackgroundLine,
  TicketsByChannelWidgetContainer,
  TicketsByChannelWidgetContainerInner,
  TicketsByChannelWidgetGraphBar,
  TicketsByChannelWidgetGraphBarContainer,
  TicketsByChannelWidgetGraphBarTypeContainer,
  TicketsByChannelWidgetGraphBarValue,
  TicketsByChannelWidgetGraphContainer,
  TicketsByChannelWidgetTitleContainer,
} from './tickets-by-channel-widget-styles'

type Props = {
  id: string
  isActive: boolean
  onRemove?: () => void
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export const TicketsByChannelWidget = React.forwardRef<any, Props>(
  ({ id, isActive, onRemove, children, ...props }, ref) => {
    const { t } = useTranslation()
    const editMode = useAppSelector((state) => state.config.editMode)
    const label = useAppSelector(
      (state) => state.settings.widgetSettings[id]?.blockLabel
    )
    const ticketTypes = useAppSelector((state) =>
      selectDataPointTicketTypes(state, id)
    )

    const total =
      ticketTypes.api +
      ticketTypes.chat +
      ticketTypes.client +
      ticketTypes.email +
      ticketTypes.webform
    const largestValue = Math.max(
      ticketTypes.api,
      ticketTypes.chat,
      ticketTypes.client,
      ticketTypes.email,
      ticketTypes.webform
    )

    return (
      <TicketsByChannelWidgetContainer
        editMode={editMode}
        isActive={isActive}
        ref={ref}
        {...props}
      >
        {editMode && label && (
          <WidgetLabelContainer>
            <Typography type="BodySmallRegular">{label}</Typography>
          </WidgetLabelContainer>
        )}
        {onRemove && (
          <WidgetRemoveContainer>
            <IconButton size="small" onClick={onRemove} type="Secondary">
              close
            </IconButton>
          </WidgetRemoveContainer>
        )}
        <AutoScroll scrollBy={112} speed={2500}>
          <TicketsByChannelWidgetContainerInner>
            <TicketsByChannelWidgetTitleContainer>
              <Typography
                type="Heading1"
                custom={{
                  weight: 700,
                  style: 'normal',
                  size: 36,
                  lineHeight: 41,
                }}
              >
                <WidgetTextOverrite>
                  {t('ticketByChannelWidget.title')}
                </WidgetTextOverrite>
              </Typography>
            </TicketsByChannelWidgetTitleContainer>
            <TicketsByChannelWidgetGraphContainer>
              <TicketsByChannelWidgetBackgroundLine left={0} />
              <TicketsByChannelWidgetBackgroundLine left={20} />
              <TicketsByChannelWidgetBackgroundLine left={40} />
              <TicketsByChannelWidgetBackgroundLine left={60} />
              <TicketsByChannelWidgetBackgroundLine left={80} />
              <GraphBar
                total={total}
                value={ticketTypes.email}
                largest={largestValue}
                text={t('ticketByChannelWidget.channelEmail')}
                primary
              />

              <GraphBar
                total={total}
                value={ticketTypes.client}
                largest={largestValue}
                text={t('ticketByChannelWidget.channelClient')}
              />

              <GraphBar
                total={total}
                value={ticketTypes.api}
                largest={largestValue}
                text={t('ticketByChannelWidget.channelApi')}
                primary
              />

              <GraphBar
                total={total}
                value={ticketTypes.chat}
                largest={largestValue}
                text={t('ticketByChannelWidget.channelChat')}
              />

              <GraphBar
                total={total}
                value={ticketTypes.webform}
                largest={largestValue}
                text={t('ticketByChannelWidget.channelWebform')}
                primary
              />
            </TicketsByChannelWidgetGraphContainer>
          </TicketsByChannelWidgetContainerInner>
        </AutoScroll>
        {editMode && children}
      </TicketsByChannelWidgetContainer>
    )
  }
)

type GraphBarProps = {
  total: number
  largest: number
  value: number
  text: string
  primary?: boolean
}

const GraphBar: React.FC<GraphBarProps> = ({
  total,
  value,
  largest,
  text,
  primary,
}) => {
  const { t } = useTranslation()
  const customTypeFont: TypographyStyles = {
    size: 20,
    lineHeight: 24,
    weight: 600,
    style: 'normal',
  }

  const percent = largest > 0 ? Math.floor((value / total) * 100) : 0
  const size = largest > 0 ? Math.floor((value / largest) * 100) : 0

  return (
    <TicketsByChannelWidgetGraphBarContainer>
      <TicketsByChannelWidgetGraphBarTypeContainer>
        <Typography type="BodyMediumRegular" custom={customTypeFont}>
          <WidgetTextOverrite>{text}</WidgetTextOverrite>
        </Typography>
      </TicketsByChannelWidgetGraphBarTypeContainer>
      <TicketsByChannelWidgetGraphBar>
        <TicketsByChannelWidgetGraphBarValue
          size={size}
          primary={Boolean(primary)}
        >
          {percent > 0 && (
            <Typography type="BodyLargeSemiBold" color="white">
              {percent}%
            </Typography>
          )}
        </TicketsByChannelWidgetGraphBarValue>
        <Typography type="BodyLargeSemiBold" color="primaryLight3">
          {value} {t('ticketByChannelWidget.tickets')}
        </Typography>
      </TicketsByChannelWidgetGraphBar>
    </TicketsByChannelWidgetGraphBarContainer>
  )
}
