import React from 'react'
import {
  SplittedStatsWidgetBottomContainer,
  SplittedStatsWidgetBottomDataContainer,
  SplittedStatsWidgetContainer,
  SplittedStatsWidgetDataPointTextOverrite,
  SplittedStatsWidgetDivider,
  SplittedStatsWidgetTopContainer,
} from './splitted-stats-widget-styles'
import {
  WidgetRemoveContainer,
  WidgetDataPointTextOverrite,
  WidgetTextOverrite,
  WidgetLabelContainer,
} from '../widget-base-styles'
import { IconButton, Typography } from '@imbox/ui-components'
import { TypographyStyles } from '@imbox/ui-components/lib/typography/typography-types'
import { useAppSelector } from '../../hooks/redux-hooks'

type Props = {
  topStat: string | number
  topText: string
  bottomLeftStat: string | number
  bottomLeftText: string
  bottomRightStat: string | number
  bottomRightText: string
  label?: string
  isActive: boolean
  onRemove?: () => void
}

export const SplittedStatsWidget = React.forwardRef<any, Props>(
  (
    {
      onRemove,
      topStat,
      topText,
      bottomLeftStat,
      bottomLeftText,
      bottomRightStat,
      bottomRightText,
      label,
      isActive,
      children,
      ...props
    },
    ref
  ) => {
    const editMode = useAppSelector((state) => state.config.editMode)
    const smallScreenHeight = window.matchMedia('(max-height: 1350px)').matches

    const getTopHeaderTextSize = (): TypographyStyles => {
      if (smallScreenHeight) {
        return {
          lineHeight: 16,
          size: 30,
          style: 'normal',
          weight: 600,
        }
      }

      return {
        lineHeight: 24,
        size: 40,
        style: 'normal',
        weight: 600,
      }
    }

    return (
      <SplittedStatsWidgetContainer editMode={editMode} isActive={isActive} ref={ref} {...props}>
        {editMode && label && (
          <WidgetLabelContainer>
            <Typography type="BodySmallRegular">{label}</Typography>
          </WidgetLabelContainer>
        )}
        {onRemove && (
          <WidgetRemoveContainer>
            <IconButton size="small" onClick={onRemove} type="Secondary">
              close
            </IconButton>
          </WidgetRemoveContainer>
        )}

        <SplittedStatsWidgetTopContainer>
          <Typography
            type="Heading1"
            custom={{
              lineHeight: 267,
              size: 232,
              style: 'normal',
              weight: 'bold',
            }}
          >
            <SplittedStatsWidgetDataPointTextOverrite>
              {topStat}
            </SplittedStatsWidgetDataPointTextOverrite>
          </Typography>

          <Typography type="Heading1" custom={getTopHeaderTextSize()}>
            <WidgetTextOverrite>{topText}</WidgetTextOverrite>
          </Typography>
        </SplittedStatsWidgetTopContainer>
        <SplittedStatsWidgetDivider />

        <SplittedStatsWidgetBottomContainer>
          <SplittedStatsWidgetBottomDataContainer>
            <Typography type="Heading1">
              <WidgetDataPointTextOverrite>
                {bottomLeftStat}
              </WidgetDataPointTextOverrite>
            </Typography>

            <Typography type="BodyLargeSemiBold">
              <WidgetTextOverrite>{bottomLeftText}</WidgetTextOverrite>
            </Typography>
          </SplittedStatsWidgetBottomDataContainer>
          <SplittedStatsWidgetBottomDataContainer>
            <Typography type="Heading1">
              <WidgetDataPointTextOverrite>
                {bottomRightStat}
              </WidgetDataPointTextOverrite>
            </Typography>

            <Typography type="BodyLargeSemiBold">
              <WidgetTextOverrite>{bottomRightText}</WidgetTextOverrite>
            </Typography>
          </SplittedStatsWidgetBottomDataContainer>
        </SplittedStatsWidgetBottomContainer>
      </SplittedStatsWidgetContainer>
    )
  }
)
