import React from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks'
import { useTranslation } from 'react-i18next'
import { TextInput } from '@imbox/ui-components'
import {
  setPageFilters,
  setGroups,
  setTargetTime,
} from '../../lib/features/settings/settings-slice'
import { MultiSelect, ValueLabelPair } from '../multi-select/multi-select'
import { useSetUpdated } from '../../hooks/use-set-updated'

export const ChatPropertyPanel: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const widgetId = useAppSelector((state) => state.propertyPanel.id)
  const widgetType = useAppSelector((state) => state.propertyPanel.type)

  const selectedPageFilters = useAppSelector(
    (state) => state.settings.widgetSettings[widgetId]?.pageFilters
  )
  const _pageFilters = useAppSelector((state) => state.settings.pageFilters)
  const selectedGroups = useAppSelector(
    (state) => state.settings.widgetSettings[widgetId]?.groups
  )
  const _groups = useAppSelector((state) => state.settings.groups)
  const selectedTargetTime = useAppSelector(
    (state) => state.settings.widgetSettings[widgetId]?.targetTime ?? '60'
  )

  useSetUpdated(
    [selectedPageFilters, selectedGroups, selectedTargetTime],
    widgetId
  )

  const onPageFilterChange = (option: any) => {
    dispatch(
      setPageFilters({
        widgetId,
        pageFilters: option,
      })
    )
  }

  const onGroupChange = (option: any) => {
    dispatch(
      setGroups({
        widgetId,
        groups: option,
      })
    )
  }

  const onTargetTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setTargetTime({
        widgetId,
        targetTime: event.target.value,
      })
    )
  }

  const pageFilters: ValueLabelPair[] = _pageFilters.map((filter) => {
    return {
      value: filter.searchName,
      label: filter.searchName,
    }
  })

  const groups: ValueLabelPair[] = _groups.map((group) => {
    return {
      value: group.id,
      label: group.name,
    }
  })

  return (
    <>
      {widgetType === 'chatCurrentWorkloadWidget' ? (
        <MultiSelect
          id="group"
          label={t('chatPropertyPanel.groupLabel')}
          isMulti
          placeholder={t('chatPropertyPanel.groupPlaceholder')}
          options={groups}
          value={selectedGroups}
          onChange={onGroupChange}
        />
      ) : (
        <>
          <MultiSelect
            id="page-filter"
            label={t('chatPropertyPanel.pageFilterLabel')}
            isMulti
            placeholder={t('chatPropertyPanel.pageFilterPlaceholder')}
            options={pageFilters}
            value={selectedPageFilters}
            onChange={onPageFilterChange}
          />
          {widgetType === 'chatServiceLevelWidget' && (
            <TextInput
              value={selectedTargetTime}
              label={t('chatPropertyPanel.slaTargetTimeLabel')}
              onChange={onTargetTimeChange}
              inputType={'number'}
              min="0"
            ></TextInput>
          )}
        </>
      )}
    </>
  )
}
