import styled, { css } from 'styled-components'

export const TopBarContainer = styled.div`
  display: none;
  @supports (display: grid) {
    grid-area: topbar;
    height: 64px;
    display: grid;
    align-items: center;
  }

  padding-left: 16px;
  padding-right: 16px;
  background: white;
  z-index: 100;
  position: fixed;
  top: 0px;

  box-shadow: ${(props) => props.theme.shadow.smallStroke};

  width: 100%;
  grid-template-areas: 'left center right';
  grid-template-columns: 1fr 1fr 1fr;
`

export const TopBarContainerLeft = styled.div`
  grid-area: left;
  display: grid;
  justify-content: flex-start;
`

export const TopBarEditContainer = styled.div`
  display: flex;
  align-items: center;
`

export const TopBarEditContent = styled.div`
  margin-left: 24px;
`

export const TopBarContainerCenter = styled.div`
  grid-area: center;
  display: grid;
  justify-content: center;
`

export const TopBarContainerRight = styled.div`
  grid-area: right;
  display: grid;
  justify-content: flex-end;
`

export const TopBarEditContentName = styled.div`
  display: flex;

  button {
    height: 22px;
    width: 22px;
    min-height: 22px;
    min-width: 22px;
    margin-left: 4px;

    &:hover {
      background: white;
    }
  }
  button > span {
    font-size: 16px;
  }
`
