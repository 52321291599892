import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AvailableThemes } from '../../../theme/theme'
import { getStoredDataAsync } from '../data-store/data-store-api'

export const themeSliceInitialState = 'light' as AvailableThemes

export const themeSlice = createSlice({
  name: 'theme',
  initialState: themeSliceInitialState,
  reducers: {
    setTheme: (state, action: PayloadAction<AvailableThemes>) => {
      return action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStoredDataAsync.fulfilled, (state, action) => {
      return action.payload.clientData.theme
    })
  },
})
