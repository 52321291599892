import { useEffect, useRef } from 'react'
import { configSlice } from '../lib/features/config/config-slice'
import { useAppDispatch, useAppSelector } from './redux-hooks'

export const useSetUpdated = (dependencies: Array<unknown>, id: string) => {
  const dispatch = useAppDispatch()
  const status = useAppSelector((state) => state.dataStore.status)
  const didMountRef = useRef(false)
  const statusRef = useRef(status)
  const shouldUpdateRef = useRef(false)
  const idRef = useRef(id)

  useEffect(() => {
    if (id !== idRef.current) {
      idRef.current = id
      return
    }

    // Dispatch setIsUpdated when getStoredDataAsync request is fulfilled
    // and it's not the fist render
    if (shouldUpdateRef.current && didMountRef.current) {
      dispatch(configSlice.actions.setIsUpdated(true))
    }

    didMountRef.current = true
  }, dependencies)

  useEffect(() => {
    if (statusRef.current !== 'success' && status === 'success') {
      shouldUpdateRef.current = true
      statusRef.current = status
    } else if (statusRef.current === 'success' && status === 'success') {
      shouldUpdateRef.current = true
    }
  }, [status])
}
