import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../../root-reducer'
import { PageFilter, TicketCategory, Group } from './settings-slice'

type ApiResPageFilters = {
  pageFilters?: Array<PageFilter>
}

function fetchPageFilters(secret: string) {
  return new Promise<ApiResPageFilters>((resolve, reject) => {
    return fetch(`/page-filters?secret=${secret}`)
      .then((response) => response.json())
      .then((d) => resolve(d))
      .catch((e) => reject(e))
  })
}

export const getPageFiltersAsync = createAsyncThunk<
  ApiResPageFilters,
  void,
  { state: RootState }
>(
  'settings/getPageFilters',
  async (_, { getState }) => {
    const queryString = new URL(window.location.href)
    const urlParams = new URLSearchParams(queryString.search)
    const secret = urlParams.get('secret') ?? ''

    const response = await fetchPageFilters(secret)
    return response
  }
)

type ApiResTicketCategories = {
  ticketCategories?: Array<TicketCategory>
}

function fetchTicketCategories(secret: string) {
  return new Promise<ApiResTicketCategories>((resolve, reject) => {
    return fetch(`/ticket-categories?secret=${secret}`)
      .then((response) => response.json())
      .then((d) => resolve(d))
      .catch((e) => reject(e))
  })
}

export const getTicketCategoriesAsync = createAsyncThunk<
  ApiResTicketCategories,
  void,
  { state: RootState }
>(
  'settings/getTicketCategories',
  async (_, { getState }) => {
    const queryString = new URL(window.location.href)
    const urlParams = new URLSearchParams(queryString.search)
    const secret = urlParams.get('secret') ?? ''

    const response = await fetchTicketCategories(secret)
    return response
  }
)

type ApiResGroups = {
  groups?: Array<Group>
}

function fetchGroups(secret: string) {
  return new Promise<ApiResGroups>((resolve, reject) => {
    return fetch(`/groups?secret=${secret}`)
      .then((response) => response.json())
      .then((d) => resolve(d))
      .catch((e) => reject(e))
  })
}

export const getGroupsAsync = createAsyncThunk<
  ApiResGroups,
  void,
  { state: RootState }
>(
  'settings/getGroups',
  async (_, { getState }) => {
    const queryString = new URL(window.location.href)
    const urlParams = new URLSearchParams(queryString.search)
    const secret = urlParams.get('secret') ?? ''

    const response = await fetchGroups(secret)
    return response
  }
)
