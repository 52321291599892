import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import GridLayout from 'react-grid-layout'
import { getStoredDataAsync } from '../data-store/data-store-api'

type LayoutState = GridLayout.Layout[]

export const layoutSliceInitialState: LayoutState = []

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: layoutSliceInitialState,
  reducers: {
    setLayout: (state, action: PayloadAction<LayoutState>) => {
      return action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStoredDataAsync.fulfilled, (state, action) => {
      return action.payload.clientData.layout
    })
  },
})
