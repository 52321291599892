import styled, { css } from 'styled-components'
import { Theme } from '../../theme/theme'
import { widgetBaseStyle } from '../widget-base-styles'

export const SingleDataPointWidgetContainer = styled.div<{
  overrideTheme?: keyof Theme['widgets']
  editMode: boolean
  isActive?: boolean
}>`
  position: relative;
  ${widgetBaseStyle}
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 0px;

  ${(props) =>
    props.overrideTheme &&
    props.theme.widgets[props.overrideTheme]?.singleDataPointWidgetTheme
      ?.plate &&
    css`
      background-color: ${props.theme.widgets[props.overrideTheme]
        ?.singleDataPointWidgetTheme?.plate};
      border: 2px solid
        ${props.isActive
          ? props.theme.color.primary
          : props.theme.widgets[props.overrideTheme]?.singleDataPointWidgetTheme
              ?.plate};
    `}
`

export const SingleDataPointWidgetHeaderContainer = styled.div<{
  showPreviousData?: boolean
}>`
  grid-area: header;
  justify-content: center;
  align-items: center;
  display: flex;
`

export const SingleDataPointWidgetHeader = styled.div<{
  overrideTheme?: keyof Theme['widgets']
  showPreviousData?: boolean
  type?: 'time' | 'digit'
}>`
  text-align: center;
  color: ${(props) =>
    props.overrideTheme &&
    props.theme.widgets[props.overrideTheme]?.singleDataPointWidgetTheme
      ?.dataPointColor
      ? props.theme.widgets[props.overrideTheme].singleDataPointWidgetTheme
          ?.dataPointColor
      : props.theme.baseWidgets.singleDataPointWidget.dataPointColor};

  @media (max-height: 1000px) {
    font-size: 70px;
  }

  @media (max-height: 830px) {
    font-size: 60px;
  }

  ${(props) =>
    props.showPreviousData && props.type !== 'time'
      ? css`
          line-height: 100px;
          @media (max-height: 1000px) {
            line-height: 70px;
          }

          @media (max-height: 830px) {
            line-height: 60px;
          }
        `
      : css`
          @media (max-height: 1000px) {
            font-size: 80px;
            line-height: 90px;
          }

          @media (max-height: 830px) {
            font-size: 70px;
            line-height: 80px;
          }
        `}
`

export const SingleDataPointWidgetTitle = styled.div<{
  overrideTheme?: keyof Theme['widgets']
}>`
  text-align: center;
  color: ${(props) =>
    props.overrideTheme &&
    props.theme.widgets[props.overrideTheme]?.singleDataPointWidgetTheme?.title
      ? props.theme.widgets[props.overrideTheme].singleDataPointWidgetTheme
          ?.title
      : props.theme.baseWidgets.singleDataPointWidget.title};
`

export const SingleDataPointWidgetIconPosition = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
`

export const SingleDataPointWidgetIconColor = styled.div<{
  overrideTheme?: keyof Theme['widgets']
}>`
  color: ${(props) =>
    props.overrideTheme &&
    props.theme.widgets[props.overrideTheme]?.singleDataPointWidgetTheme?.icon
      ? props.theme.widgets[props.overrideTheme].singleDataPointWidgetTheme
          ?.icon
      : props.theme.baseWidgets.singleDataPointWidget.icon};
`
export const PreviousSingleDataContainer = styled.div<{
  overrideTheme?: keyof Theme['widgets']
}>`
  display: flex;
  align-items: center;

  h4 {
    div {
      @media (max-height: 830px) {
        font-size: 20px;
        line-height: 23px;
      }
    }
  }

  .material-icons {
    color: ${(props) =>
      props.overrideTheme &&
      props.theme.widgets[props.overrideTheme]?.singleDataPointWidgetTheme
        ?.dataPointColor
        ? props.theme.widgets[props.overrideTheme].singleDataPointWidgetTheme
            ?.dataPointColor
        : props.theme.baseWidgets.singleDataPointWidget.dataPointColor};
  }
`

export const WidgetSingleDataPointTextOverrite = styled.div<{
  overrideTheme?: keyof Theme['widgets']
}>`
  margin-left: 4px;
  color: ${(props) =>
    props.overrideTheme &&
    props.theme.widgets[props.overrideTheme]?.singleDataPointWidgetTheme
      ?.dataPointColor
      ? props.theme.widgets[props.overrideTheme].singleDataPointWidgetTheme
          ?.dataPointColor
      : props.theme.baseWidgets.singleDataPointWidget.dataPointColor};
`
