import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, Switch, TextInput, Typography } from '@imbox/ui-components'
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks'
import {
  Container,
  HeadingContainer,
  InnerContainer,
  InputContainer,
} from './property-panel-styles'
import {
  setBlockLabel,
  togglePreviousStats,
} from '../../lib/features/settings/settings-slice'
import { ChatPropertyPanel } from './chat-property-panel'
import { CallPropertyPanel } from './call-property-panel'
import { TicketPropertyPanel } from './ticket-property-panel'
import { OtherPropertyPanel } from './other-property-panel'
import { closePropertyPanel } from '../../lib/features/property-panel/property-panel-slice'
import { LanguageSelector } from '../language-selector/language-selector'
import { ThemeSelector } from '../theme-selector'
import { setName } from '../../lib/features/meta/meta-slice'
import { useSetUpdated } from '../../hooks/use-set-updated'

export const PropertyPanel: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isOpen = useAppSelector((state) => state.propertyPanel.isOpen)
  const id = useAppSelector((state) => state.propertyPanel.id)
  const type = useAppSelector((state) => state.propertyPanel.type)
  const name = useAppSelector((state) => state.meta.name)
  const label =
    useAppSelector((state) => state.settings.widgetSettings[id]?.blockLabel) ??
    ''
  const showPreviousData = useAppSelector(
    (state) => state.settings.showPreviousStats
  )
  const isUpdated = useAppSelector((state) => state.config.isUpdated)

  useSetUpdated([label, showPreviousData, name], id)

  const handleLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setBlockLabel({
        widgetId: id,
        blockLabel: event.target.value,
      })
    )
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(togglePreviousStats(event.target.checked))
  }

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setName(event.target.value))
  }

  const handleClose = () => {
    dispatch(closePropertyPanel())
  }

  return (
    <Container isOpen={isOpen}>
      <HeadingContainer>
        <Typography type="BodyLargeSemiBold">
          {id === 'settings'
            ? t('propertyPanel.titleSettings')
            : t('propertyPanel.titleProperties')}
        </Typography>
        <IconButton size="small" onClick={handleClose} type="Secondary">
          close
        </IconButton>
      </HeadingContainer>

      {id === 'settings' ? (
        <InputContainer>
          <TextInput
            label={t('propertyPanel.nameLabel')}
            placeholder={t('propertyPanel.namePlaceholder')}
            onChange={onNameChange}
            value={name}
          />
          <ThemeSelector />
          <LanguageSelector />
          <Switch
            onChange={onChange}
            label={t('propertyPanel.togglePreviousStats')}
            on={showPreviousData}
          />
        </InputContainer>
      ) : (
        <>
          <InnerContainer>
            <Typography type="BodyMediumSemiBold">
              {t(type as any, { returnObjects: true }).listItemTitle}
            </Typography>
            <Typography type="BodyMediumRegular">
              {t(type as any, { returnObjects: true }).description}
            </Typography>
          </InnerContainer>

          <InputContainer>
            {type !== 'labelWidget' && (
              <TextInput
                onChange={handleLabelChange}
                label={t('propertyPanel.blockLabel')}
                caption={t('propertyPanel.blockCaption')}
                placeholder={t('propertyPanel.blockPlaceholder')}
                value={label}
                id={id}
              ></TextInput>
            )}

            {(type === 'chatsTotalWidget' ||
              type === 'chatsMissedWidget' ||
              type === 'chatsAnsweredWidget' ||
              type === 'chatTableWidget' ||
              type === 'chatsAvgWaitTimeWidget' ||
              type === 'chatsAvgDurationWidget' ||
              type === 'chatsMaxWaitTimeWidget' ||
              type === 'chatsTimesWidget' ||
              type === 'chatServiceLevelWidget' ||
              type === 'chatCurrentWorkloadWidget') && (
              <ChatPropertyPanel key={id} />
            )}

            {(type === 'callsTotalCompletedWidget' ||
              type === 'callsAvgTalkTimeWidget' ||
              type === 'callsTotalMissedWidget' ||
              type === 'callsTotalAbandonedWidget' ||
              type === 'callsAvgWaitTimeWidget' ||
              type === 'callsMaxWaitTimeWidget' ||
              type === 'callsCurrentWorkloadWidget' ||
              type === 'callsTimesWidget' ||
              type === 'callsTotalAnsweredWidget' ||
              type === 'callServiceLevelWidget') && (
              <CallPropertyPanel key={id} />
            )}

            {(type === 'ticketByChannelWidget' ||
              type === 'ticketUnsolvedWidget' ||
              type === 'ticketServedWidget' ||
              type === 'ticketIncomingWidget') && (
              <TicketPropertyPanel key={id} />
            )}

            {(type === 'weatherWidget' || type === 'labelWidget') && (
              <OtherPropertyPanel key={id} />
            )}
          </InputContainer>
        </>
      )}
    </Container>
  )
}
