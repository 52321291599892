import styled from 'styled-components'
import { Theme } from '../../theme/theme'
import {
  widgetBaseStyle,
  WidgetDataPointTextOverrite,
} from '../widget-base-styles'

export const AggreagatedTimesWidgetContainer = styled.div<{
  overrideTheme?: keyof Theme['widgets']
  editMode: boolean
  isActive: boolean
}>`
  ${widgetBaseStyle};
  padding: 0px;
`

export const AggreagatedTimesWidgetInnerContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: space-around;
  @supports (justify-content: space-evenly) {
    justify-content: space-evenly;
  }
`

export const AggreagatedTimesWidgetContentContainer = styled.div<{
  overrideTheme?: keyof Theme['widgets']
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const AggreagatedTimesWidgetTextOverrite = styled(
  WidgetDataPointTextOverrite
)`
  @media (max-height: 920px) {
    font-size: 56px;
    line-height: 60px;
  }

  @media (max-height: 764px) {
    font-size: 46px;
    line-height: 50px;
  }
`
