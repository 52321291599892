import { Widget } from './widgets-types'

export type ChatWidgetKeys =
  | 'chatTableWidget'
  | 'chatsMissedWidget'
  | 'chatsTotalWidget'
  | 'chatsAnsweredWidget'
  | 'chatsAvgWaitTimeWidget'
  | 'chatsAvgDurationWidget'
  | 'chatsMaxWaitTimeWidget'
  | 'chatsTimesWidget'
  | 'chatCurrentWorkloadWidget'
  | 'chatServiceLevelWidget'

export const chatWidgets: {
  [key in ChatWidgetKeys]: Widget
} = {
  chatTableWidget: {
    i: 'chatTableWidget',
    h: 2,
    w: 4,
    minW: 2,
    minH: 1,
    isResizable: true,
  },

  chatsMissedWidget: {
    i: 'chatsMissedWidget',
    h: 1,
    w: 1,
    maxW: 2,
    maxH: 2,
    isResizable: true,
    singleDataPointProps: {
      theme: 'chatsMissedWidget',
      type: 'digit',
    },
  },

  chatsTotalWidget: {
    i: 'chatsTotalWidget',
    h: 1,
    w: 1,
    maxW: 2,
    maxH: 2,
    isResizable: true,
    singleDataPointProps: {
      theme: 'chatsTotalWidget',
      type: 'digit',
    },
  },

  chatsAnsweredWidget: {
    i: 'chatsAnsweredWidget',
    h: 1,
    w: 1,
    maxW: 2,
    maxH: 2,
    isResizable: true,
    singleDataPointProps: {
      theme: 'chatsAnsweredWidget',
      type: 'digit',
    },
  },

  chatsAvgWaitTimeWidget: {
    i: 'chatsAvgWaitTimeWidget',
    h: 1,
    w: 2,
    isResizable: false,
    singleDataPointProps: { type: 'time' },
  },

  chatsAvgDurationWidget: {
    i: 'chatsAvgDurationWidget',
    h: 1,
    w: 2,
    isResizable: false,
    singleDataPointProps: { type: 'time' },
  },

  chatsMaxWaitTimeWidget: {
    i: 'chatsMaxWaitTimeWidget',
    h: 1,
    w: 2,
    isResizable: false,
    singleDataPointProps: { type: 'time' },
  },

  chatCurrentWorkloadWidget: {
    i: 'chatCurrentWorkloadWidget',
    h: 2,
    w: 2,
  },

  chatsTimesWidget: {
    i: 'chatsTimesWidget',
    h: 2,
    w: 2,
    isResizable: false,
  },

  chatServiceLevelWidget: {
    i: 'chatServiceLevelWidget',
    h: 1,
    w: 1,
    minH: 1,
    minW: 1,
    isResizable: true,
  },
}
