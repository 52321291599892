import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../hooks/redux-hooks'
import { selectDataPointCallQueues } from '../../lib/features/data-points/data-points-slice'
import {
  WidgetLabelContainer,
  WidgetRemoveContainer,
} from '../widget-base-styles'
import { IconButton, Typography } from '@imbox/ui-components'
import {
  TableCellLarge,
  TableHeaderLarge,
  TableWidgetContainer,
  WidgetTableContainer,
} from '../table/table-styles'
import { formatDuration } from '../../lib/format-duration'
import { AutoScroll } from '../auto-scroll/auto-scroll'

type Props = {
  id: string
  isActive: boolean
  onRemove?: () => void
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export const CallQueuesWidget = React.forwardRef<any, Props>(
  ({ id, isActive, onRemove, children, ...props }, ref) => {
    const { t } = useTranslation()
    const queues = useAppSelector(selectDataPointCallQueues)
    const editMode = useAppSelector((state) => state.config.editMode)
    const label = useAppSelector(
      (state) => state.settings.widgetSettings[id]?.blockLabel
    )
    const itemRef = useRef<HTMLTableRowElement | null>(null)
    const [rowHeight, setRowHeight] = useState<number>()

    useEffect(() => {
      setRowHeight(itemRef.current?.clientHeight)
    }, [])

    return (
      <TableWidgetContainer
        editMode={editMode}
        isActive={isActive}
        ref={ref}
        {...props}
      >
        {editMode && label && (
          <WidgetLabelContainer>
            <Typography type="BodySmallRegular">{label}</Typography>
          </WidgetLabelContainer>
        )}
        {onRemove && (
          <WidgetRemoveContainer>
            <IconButton size="small" onClick={onRemove} type="Secondary">
              close
            </IconButton>
          </WidgetRemoveContainer>
        )}
        <AutoScroll scrollBy={rowHeight} speed={2500}>
          <WidgetTableContainer>
            <table>
              <thead>
                <tr>
                  <th>
                    <TableHeaderLarge>
                      {t('callQueuesWidget.nameHeader')}
                    </TableHeaderLarge>
                  </th>
                  <th>
                    <TableHeaderLarge>
                      {t('callQueuesWidget.completedHeader')}
                    </TableHeaderLarge>
                  </th>
                  <th>
                    <TableHeaderLarge>
                      {t('callQueuesWidget.inQueueHeader')}
                    </TableHeaderLarge>
                  </th>
                  <th>
                    <TableHeaderLarge>
                      {t('callQueuesWidget.incomingHeader')}
                    </TableHeaderLarge>
                  </th>
                  <th>
                    <TableHeaderLarge>
                      {t('callQueuesWidget.outgoingHeader')}
                    </TableHeaderLarge>
                  </th>
                  <th>
                    <TableHeaderLarge>
                      {t('callQueuesWidget.timeInQueueHeader')}
                    </TableHeaderLarge>
                  </th>
                  <th>
                    <TableHeaderLarge>
                      {t('callQueuesWidget.callDurationHeader')}
                    </TableHeaderLarge>
                  </th>
                </tr>
              </thead>
              <tbody>
                {queues.live.map((queue, index) => {
                  const even = index % 2 === 0

                  return (
                    <tr key={index} ref={itemRef}>
                      <td>
                        <TableCellLarge
                          position="flex-start"
                          type="default"
                          highEmphasis={even}
                        >
                          {queue.name}
                        </TableCellLarge>
                      </td>
                      <td>
                        <TableCellLarge
                          position="flex-start"
                          type="default"
                          highEmphasis={even}
                        >
                          {queue.total}
                        </TableCellLarge>
                      </td>
                      <td>
                        <TableCellLarge
                          position="flex-start"
                          type="default"
                          highEmphasis={even}
                        >
                          {queue.waiting}
                        </TableCellLarge>
                      </td>
                      <td>
                        <TableCellLarge
                          position="flex-start"
                          type="default"
                          highEmphasis={even}
                        >
                          {queue.totalIncoming}
                        </TableCellLarge>
                      </td>
                      <td>
                        <TableCellLarge
                          position="flex-start"
                          type="default"
                          highEmphasis={even}
                        >
                          {queue.totalOutgoing}
                        </TableCellLarge>
                      </td>
                      <td>
                        <TableCellLarge
                          position="flex-start"
                          type="default"
                          highEmphasis={even}
                        >
                          {formatDuration(queue.avgWaitTime)}
                        </TableCellLarge>
                      </td>
                      <td>
                        <TableCellLarge
                          position="flex-start"
                          type="default"
                          highEmphasis={even}
                        >
                          {formatDuration(queue.avgTalkTime)}
                        </TableCellLarge>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </WidgetTableContainer>
        </AutoScroll>
        {editMode && children}
      </TableWidgetContainer>
    )
  }
)
