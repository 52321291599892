import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getStoredDataAsync } from '../data-store/data-store-api'
import { gridDataSlice } from '../grid-data/grid-data-slice'
import {
  getGroupsAsync,
  getPageFiltersAsync,
  getTicketCategoriesAsync,
} from './settings-api'
import { ValueLabelPair } from '../../../components/multi-select/multi-select'

export type WidgetSettings = Record<
  string,
  {
    ticketCategoryIds?: Array<ValueLabelPair>
    pageFilters?: Array<ValueLabelPair>
    queues?: Array<ValueLabelPair>
    targetTime?: string
    groups?: Array<ValueLabelPair>
    blockLabel?: string
    weatherWidgetCity?: string
    label?: string
  }
>

export type PageFilter = {
  id: number
  searchName: string
  searchFilter: string
}

export type TicketCategory = {
  id: number
  categoryName: string
  categoryEmail: string
}

export type Group = {
  id: number
  name: string
}

type SettingsState = {
  language: string
  showPreviousStats: boolean
  pageFilters: Array<PageFilter>
  ticketCategories: Array<TicketCategory>
  groups: Array<Group>
  widgetSettings: WidgetSettings
}

export const settingsSliceInitialState: SettingsState = {
  language: 'en',
  showPreviousStats: true,
  pageFilters: [],
  ticketCategories: [],
  groups: [],
  widgetSettings: {},
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: settingsSliceInitialState,
  reducers: {
    togglePreviousStats: (state, action: PayloadAction<boolean>) => {
      state.showPreviousStats = action.payload
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload
    },
    setTicketCategoryIds: (
      state,
      action: PayloadAction<{
        widgetId: string
        ticketCategoryIds: Array<ValueLabelPair>
      }>
    ) => {
      state.widgetSettings[action.payload.widgetId] = {
        ...state.widgetSettings[action.payload.widgetId],
        ticketCategoryIds: action.payload.ticketCategoryIds,
      }
    },
    setPageFilters: (
      state,
      action: PayloadAction<{
        widgetId: string
        pageFilters: Array<ValueLabelPair>
      }>
    ) => {
      state.widgetSettings[action.payload.widgetId] = {
        ...state.widgetSettings[action.payload.widgetId],
        pageFilters: action.payload.pageFilters,
      }
    },
    setQueues: (
      state,
      action: PayloadAction<{
        widgetId: string
        queues: Array<ValueLabelPair>
      }>
    ) => {
      state.widgetSettings[action.payload.widgetId] = {
        ...state.widgetSettings[action.payload.widgetId],
        queues: action.payload.queues,
      }
    },
    setTargetTime: (
      state,
      action: PayloadAction<{
        widgetId: string
        targetTime: string
      }>
    ) => {
      state.widgetSettings[action.payload.widgetId] = {
        ...state.widgetSettings[action.payload.widgetId],
        targetTime: action.payload.targetTime,
      }
    },
    setGroups: (
      state,
      action: PayloadAction<{
        widgetId: string
        groups: Array<ValueLabelPair>
      }>
    ) => {
      state.widgetSettings[action.payload.widgetId] = {
        ...state.widgetSettings[action.payload.widgetId],
        groups: action.payload.groups,
      }
    },
    setBlockLabel: (
      state,
      action: PayloadAction<{
        widgetId: string
        blockLabel: string
      }>
    ) => {
      state.widgetSettings[action.payload.widgetId] = {
        ...state.widgetSettings[action.payload.widgetId],
        blockLabel: action.payload.blockLabel,
      }
    },
    setCity: (
      state,
      action: PayloadAction<{
        widgetId: string
        city: string
      }>
    ) => {
      state.widgetSettings[action.payload.widgetId] = {
        ...state.widgetSettings[action.payload.widgetId],
        weatherWidgetCity: action.payload.city,
      }
    },
    setLabel: (
      state,
      action: PayloadAction<{
        widgetId: string
        label: string
      }>
    ) => {
      state.widgetSettings[action.payload.widgetId] = {
        ...state.widgetSettings[action.payload.widgetId],
        label: action.payload.label,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(gridDataSlice.actions.removeData, (state, action) => {
        delete state.widgetSettings[action.payload]
      })
      .addCase(getPageFiltersAsync.fulfilled, (state, action) => {
        if (action.payload.pageFilters) {
          state.pageFilters = action.payload.pageFilters
        }
      })
      .addCase(getTicketCategoriesAsync.fulfilled, (state, action) => {
        if (action.payload.ticketCategories) {
          state.ticketCategories = action.payload.ticketCategories
        }
      })
      .addCase(getGroupsAsync.fulfilled, (state, action) => {
        if (action.payload.groups) {
          state.groups = action.payload.groups
        }
      })
      .addCase(getStoredDataAsync.fulfilled, (state, action) => {
        return action.payload.clientData.settings
      })
  },
})

export const {
  setCity,
  togglePreviousStats,
  setLanguage,
  setTicketCategoryIds,
  setPageFilters,
  setQueues,
  setTargetTime,
  setGroups,
  setBlockLabel,
  setLabel,
} = settingsSlice.actions
