import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationEn from './en/translation.json'
import translationSV from './sv/translation.json'

export type LanguageCodes = 'en' | 'sv'
export const resources = {
  en: {
    translation: translationEn,
  },
  sv: {
    translation: translationSV,
  },
} as const

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react handles this
    },
    resources,
  })

export default i18n
