import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks'
import format from 'date-fns/format'
import {
  TopBarContainer,
  TopBarContainerCenter,
  TopBarContainerLeft,
  TopBarContainerRight,
  TopBarEditContainer,
  TopBarEditContent,
  TopBarEditContentName,
} from './top-bar-styles'
import { Button, IconButton, Typography } from '@imbox/ui-components'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useTranslation } from 'react-i18next'
import { saveStoredDataAsync } from '../../lib/features/data-store/data-store-api'
import { propertyPanelSlice } from '../../lib/features/property-panel/property-panel-slice'
import { configSlice } from '../../lib/features/config/config-slice'
import { generatePublicLinkAsync } from '../../lib/features/generate-link-api'

export const TopBar: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const editMode = useAppSelector((state) => state.config.editMode)
  const name = useAppSelector((state) => state.meta.name)
  const updatedAt = useAppSelector((state) => state.meta.updatedAt)
  const loading = useAppSelector(
    (state) => state.dataPoints.status.fetch === 'loading'
  )
  const isOpen = useAppSelector((state) => state.propertyPanel.isOpen)
  const propertyPanelId = useAppSelector((state) => state.propertyPanel.id)
  const isUpdated = useAppSelector((state) => state.config.isUpdated)
  const generatedLink = useAppSelector((state) => state.config.generatedLink)
  const language = useAppSelector((state) => state.settings.language)
  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState(false)
  const [copyLinkLabel, setCopyLinkLabel] = useState('')
  const [saveLabel, setSaveLabel] = useState('')

  const handleGoBack = () => {
    // Send to admin
    parent.postMessage(JSON.stringify({ action: 'back' }), '*')
  }

  const toggleSettings = () => {
    if (isOpen) {
      if (propertyPanelId !== 'settings') {
        dispatch(
          propertyPanelSlice.actions.openPropertyPanel({ id: 'settings' })
        )
      } else {
        dispatch(propertyPanelSlice.actions.closePropertyPanel())
      }
    } else {
      dispatch(propertyPanelSlice.actions.openPropertyPanel({ id: 'settings' }))
    }
  }

  const handleSaveClick = () => {
    setIsSaving(true)
    dispatch(saveStoredDataAsync()).then(() => {
      setSaveLabel(t('topBar.savedLabel'))
      setIsSaving(false)
    })
    setTimeout(() => {
      setSaveLabel(t('topBar.saveLabel'))
      dispatch(configSlice.actions.setIsUpdated(false))
    }, 1500)
  }

  const generateLink = () => {
    setIsSaving(true)
    dispatch(generatePublicLinkAsync())
      .unwrap()
      .then(() => {
        setIsSaving(false)
      })
      .catch((error) => {
        setIsSaving(false)
        setError(error)
      })
  }

  useEffect(() => {
    setCopyLinkLabel(t('topBar.copyLinkLabel'))
    setSaveLabel(t('topBar.saveLabel'))
  }, [language])

  const copyLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    navigator.clipboard.writeText(generatedLink)
    setCopyLinkLabel(t('topBar.copiedLabel'))

    setTimeout(() => {
      setCopyLinkLabel(t('topBar.copyLinkLabel'))
    }, 1500)
  }

  const renderButton = () => {
    const generateLinkLabel = error
      ? t('general.errorMessage')
      : t('topBar.generateLinkLabel')

    return isUpdated ? (
      <Button type="PrimaryFilled" onClick={handleSaveClick}>
        {isSaving ? '...' : saveLabel}
      </Button>
    ) : (
      <>
        {generatedLink && generatedLink !== '' ? (
          <Button type="PrimaryOutlined" onClick={copyLink}>
            {copyLinkLabel}
          </Button>
        ) : (
          <Button type="PrimaryOutlined" onClick={generateLink}>
            {isSaving ? '...' : generateLinkLabel}
          </Button>
        )}
      </>
    )
  }

  return editMode ? (
    <>
      <TopBarContainer>
        <TopBarContainerLeft>
          <TopBarEditContainer>
            <IconButton onClick={handleGoBack} type="Secondary">
              arrow_back
            </IconButton>
            <TopBarEditContent>
              <TopBarEditContentName>
                <Typography type="BodyLargeSemiBold">
                  {name === '' ? 'Untitled' : name}
                </Typography>
                <IconButton
                  onClick={toggleSettings}
                  type="Secondary"
                  size="small"
                >
                  edit
                </IconButton>
              </TopBarEditContentName>
              {updatedAt && (
                <Typography type="BodySmallMedium" color="neutral4">
                  {t('topBar.dateUpdated')}{' '}
                  {format(Date.parse(updatedAt), 'E HH:mm')}
                </Typography>
              )}
            </TopBarEditContent>
          </TopBarEditContainer>
        </TopBarContainerLeft>
        <TopBarContainerCenter>
          <Row noGutters>
            <Col>{renderButton()}</Col>
          </Row>
        </TopBarContainerCenter>
        <TopBarContainerRight>
          <IconButton
            onClick={toggleSettings}
            type="Secondary"
            active={isOpen && propertyPanelId === 'settings'}
          >
            settings
          </IconButton>
        </TopBarContainerRight>
      </TopBarContainer>
    </>
  ) : null
}
