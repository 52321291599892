import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../hooks/redux-hooks'
import {
  selectDataPointChatAgentsOnline,
  selectDataPointChatActiveChats,
  selectDataPointChatTotalSlots,
} from '../../lib/features/data-points/data-points-slice'
import { SplittedStatsWidget } from '../splitted-stats-widget/splitted-stats-widget'

type Props = {
  id: string
  isActive: boolean
  onRemove?: () => void
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export const ChatCurrentWorkloadWidget = React.forwardRef<any, Props>(
  ({ id, isActive, onRemove, children, ...props }, ref) => {
    const { t } = useTranslation()

    const agentsOnline = useAppSelector((state) =>
      selectDataPointChatAgentsOnline(state, id)
    )
    const activeChats = useAppSelector((state) =>
      selectDataPointChatActiveChats(state, id)
    )
    const totalSlots = useAppSelector((state) =>
      selectDataPointChatTotalSlots(state, id)
    )
    const label = useAppSelector(
      (state) => state.settings.widgetSettings[id]?.blockLabel
    )

    return (
      <SplittedStatsWidget
        onRemove={onRemove}
        topStat={activeChats.live}
        topText={t('chatCurrentWorkloadWidget.activeChats')}
        bottomLeftStat={`${activeChats.live}/${totalSlots}`}
        bottomLeftText={t('chatCurrentWorkloadWidget.slots')}
        bottomRightStat={agentsOnline}
        bottomRightText={t('chatCurrentWorkloadWidget.agentsOnline')}
        label={label}
        isActive={isActive}
        ref={ref}
        {...props}
      />
    )
  }
)
