import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../hooks/redux-hooks'
import { selectDataPointChatWaitTimes } from '../../lib/features/data-points/data-points-slice'
import { ServiceLevelWidget } from '../service-level-widget/service-level-widget'

type Props = {
  id: string
  isActive: boolean
  onRemove?: () => void
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export const ChatServiceLevelWidget = React.forwardRef<any, Props>(
  ({ id, isActive, onRemove, ...props }, ref) => {
    const { t } = useTranslation()
    const chatWaitTimes = useAppSelector((state) =>
      selectDataPointChatWaitTimes(state, id)
    )

    return (
      <ServiceLevelWidget
        ref={ref}
        id={id}
        title={t('chatServiceLevelWidget.title')}
        waitTimes={chatWaitTimes}
        isActive={isActive}
        onRemove={onRemove}
        {...props}
      />
    )
  }
)
