import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ReactGridLayout from 'react-grid-layout'
import { getUrlParams } from '../../get-url-parms'
import { getStoredDataAsync } from '../data-store/data-store-api'
import { generatePublicLinkAsync } from '../generate-link-api'

type LayoutState = ReactGridLayout.Layout[]

type ConfigState = {
  editMode: boolean
  fetchPhone: boolean
  phoneDefaultLayout: boolean
  chatDefaultLayout: boolean
  ticketDefaultLayout: boolean
  multiDefaultLayout: boolean
  isUpdated: boolean
  storedLayout: LayoutState
  generatedLink: string
}

export const configSliceInitialState: ConfigState = {
  editMode: getUrlParams().editMode,
  fetchPhone: true,
  phoneDefaultLayout: false,
  chatDefaultLayout: false,
  ticketDefaultLayout: false,
  multiDefaultLayout: false,
  isUpdated: false,
  storedLayout: [],
  generatedLink: '',
}

export const configSlice = createSlice({
  name: 'config',
  initialState: configSliceInitialState,
  reducers: {
    toggleEditMode: (state) => {
      state.editMode = !state.editMode
    },

    setFetchPhone: (state, action: PayloadAction<boolean>) => {
      state.fetchPhone = action.payload
    },

    setUsingCustomLayout: (state) => {
      state.phoneDefaultLayout = false
      state.chatDefaultLayout = false
      state.ticketDefaultLayout = false
      state.multiDefaultLayout = false
    },

    setUsePhoneDefaultLayout: (state) => {
      state.phoneDefaultLayout = true
      state.chatDefaultLayout = false
      state.ticketDefaultLayout = false
      state.multiDefaultLayout = false
    },

    setUseChatDefaultLayout: (state) => {
      state.chatDefaultLayout = true
      state.phoneDefaultLayout = false
      state.ticketDefaultLayout = false
      state.multiDefaultLayout = false
    },

    setUseTicketDefaultLayout: (state) => {
      state.ticketDefaultLayout = true
      state.phoneDefaultLayout = false
      state.chatDefaultLayout = false
      state.multiDefaultLayout = false
    },

    setUseMultiDefaultLayout: (state) => {
      state.ticketDefaultLayout = false
      state.phoneDefaultLayout = false
      state.chatDefaultLayout = false
      state.multiDefaultLayout = true
    },

    setIsUpdated: (state, action: PayloadAction<boolean>) => {
      state.isUpdated = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStoredDataAsync.fulfilled, (state, action) => {
        state.storedLayout = action.payload.clientData.layout
      })
      .addCase(generatePublicLinkAsync.fulfilled, (state, action) => {
        state.generatedLink = `${location.protocol}//${location.host}/${action.payload.code}`
      })
  },
})
