import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../hooks/redux-hooks'
import {
  selectDataPointCallsAvgTalkTime,
  selectDataPointCallsAvgWaitTime,
  selectDataPointMaxWaitTime,
} from '../../lib/features/data-points/data-points-slice'
import { AggregatedTimesWidget } from '../aggregated-times-widget/aggregated-times-widget'

type Props = {
  id: string
  isActive: boolean
  onRemove?: () => void
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export const CallTimesWidget = React.forwardRef<any, Props>(
  ({ id, isActive, onRemove, ...props }, ref) => {
    const { t } = useTranslation()
    const callsAvgTalkTimeWidget = useAppSelector((state) =>
      selectDataPointCallsAvgTalkTime(state, id)
    )
    const callsAvgWaitTimeWidget = useAppSelector((state) =>
      selectDataPointCallsAvgWaitTime(state, id)
    )
    const callsMaxWaitTimeWidget = useAppSelector((state) =>
      selectDataPointMaxWaitTime(state, id)
    )
    const label = useAppSelector(
      (state) => state.settings.widgetSettings[id]?.blockLabel
    )

    return (
      <AggregatedTimesWidget
        onRemove={onRemove}
        ref={ref}
        firstData={callsAvgTalkTimeWidget.live}
        seccondData={callsAvgWaitTimeWidget.live}
        thirdData={callsMaxWaitTimeWidget.live}
        firstTitle={t('callsAvgTalkTimeWidget.title')}
        seccondTitle={t('callsAvgWaitTimeWidget.title')}
        thirdTitle={t('callsMaxWaitTimeWidget.title')}
        label={label}
        isActive={isActive}
        {...props}
      />
    )
  }
)
