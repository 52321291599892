import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../hooks/redux-hooks'
import {
  selectDataPointMaxWaitTime,
  selectDataPointCallsTotalIncoming,
  selectDataPointCallsTotalAbandoned,
  selectDataPointCallsTotal,
  selectDataPointCallsTotalMissed,
  selectDataPointCallsAvgWaitTime,
  selectDataPointCallsAvgTalkTime,
  selectDataPointChatMissed,
  selectDataPointChatTotal,
  selectDataPointChatAnswered,
  selectDataPointChatAvgWaitTime,
  selectDataPointChatAvgChatTime,
  selectDataPointChatMaxWaitTime,
  selectDataPointTicketIncoming,
  selectDataPointTicketServed,
  selectDataPointTicketUnsolved,
} from '../../lib/features/data-points/data-points-slice'
import { SingleDataPointWidgets, Widget } from '../../lib/widgets/widgets-types'
import { SingleDataPointWidget } from '../single-data-point-widget/single-data-point-widget'

const dataPointMap = {
  // phone
  callsTotalMissedWidget: selectDataPointCallsTotalMissed,
  callsTotalAnsweredWidget: selectDataPointCallsTotalIncoming,
  callsTotalAbandonedWidget: selectDataPointCallsTotalAbandoned,
  callsTotalCompletedWidget: selectDataPointCallsTotal,

  callsAvgWaitTimeWidget: selectDataPointCallsAvgWaitTime,
  callsAvgTalkTimeWidget: selectDataPointCallsAvgTalkTime,
  callsMaxWaitTimeWidget: selectDataPointMaxWaitTime,

  // chat
  chatsMissedWidget: selectDataPointChatMissed,
  chatsTotalWidget: selectDataPointChatTotal,
  chatsAnsweredWidget: selectDataPointChatAnswered,

  chatsAvgWaitTimeWidget: selectDataPointChatAvgWaitTime,
  chatsAvgDurationWidget: selectDataPointChatAvgChatTime,
  chatsMaxWaitTimeWidget: selectDataPointChatMaxWaitTime,

  // ticket
  ticketIncomingWidget: selectDataPointTicketIncoming,
  ticketUnsolvedWidget: selectDataPointTicketUnsolved,
  ticketServedWidget: selectDataPointTicketServed,
}

type WidgetSingleDataPoint = Widget & {
  i: SingleDataPointWidgets
}

export type DataPoint = {
  live: number
  previous: number
}

type Props = {
  widget: Required<WidgetSingleDataPoint>
  id: string
  onRemove?: () => void
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  isActive: boolean
}

export const BuildSingleDataPointWidget = React.forwardRef<any, Props>(
  ({ widget, id, isActive, onRemove, ...props }, ref) => {
    const { t } = useTranslation()
    const dataPoint: DataPoint = useAppSelector((state) =>
      dataPointMap[widget.i](state, id)
    )

    return (
      <SingleDataPointWidget
        data={dataPoint}
        theme={widget.singleDataPointProps.theme}
        title={t(widget.i as any, { returnObjects: true }).title}
        icon={widget.singleDataPointProps.icon}
        dataType={widget.singleDataPointProps.type}
        id={id}
        type={widget.i}
        isActive={isActive}
        onRemove={onRemove}
        ref={ref}
        {...props}
      />
    )
  }
)
