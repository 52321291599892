import { Widget } from './widgets-types'

export type TicketWidgetKeys =
  | 'ticketByChannelWidget'
  | 'ticketIncomingWidget'
  | 'ticketUnsolvedWidget'
  | 'ticketServedWidget'

export const ticketWidgets: {
  [key in TicketWidgetKeys]: Widget
} = {
  ticketByChannelWidget: {
    i: 'ticketByChannelWidget',
    h: 3,
    w: 4,
    minW: 3,
    minH: 3,
    isResizable: true,
  },
  ticketIncomingWidget: {
    i: 'ticketIncomingWidget',
    h: 1,
    w: 1,
    maxW: 2,
    maxH: 2,
    isResizable: true,
    singleDataPointProps: {
      type: 'digit',
    },
  },
  ticketUnsolvedWidget: {
    i: 'ticketUnsolvedWidget',
    h: 1,
    w: 1,
    maxW: 2,
    maxH: 2,
    isResizable: true,
    singleDataPointProps: {
      type: 'digit',
    },
  },
  ticketServedWidget: {
    i: 'ticketServedWidget',
    h: 1,
    w: 1,
    maxW: 2,
    maxH: 2,
    isResizable: true,
    singleDataPointProps: {
      type: 'digit',
    },
  },
}
