import { IconButton, Typography } from '@imbox/ui-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../hooks/redux-hooks'
import { WidgetRemoveContainer } from '../widget-base-styles'
import {
  LabelWidgetContainer,
  LabelWidgetInner,
  LabelWidgetPlaceholder,
  LabelWidgetText,
} from './label-widget-styles'

type Props = {
  id: string
  isActive: boolean
  onRemove?: () => void
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export const LabelWidget = React.forwardRef<any, Props>(
  ({ id, isActive, onRemove, children, ...props }, ref) => {
    const { t } = useTranslation()
    const editMode = useAppSelector((state) => state.config.editMode)
    const label = useAppSelector(
      (state) => state.settings.widgetSettings[id]?.label
    )

    return (
      <LabelWidgetContainer
        editMode={editMode}
        isActive={isActive}
        ref={ref}
        {...props}
      >
        <>
          {onRemove && (
            <WidgetRemoveContainer>
              <IconButton size="small" onClick={onRemove} type="Secondary">
                close
              </IconButton>
            </WidgetRemoveContainer>
          )}
          <LabelWidgetInner>
            <Typography type="BodyLargeSemiBold">
              {label ? (
                <LabelWidgetText>{label}</LabelWidgetText>
              ) : (
                <LabelWidgetPlaceholder>
                  {t('otherPropertyPanel.labelPlaceholder')}
                </LabelWidgetPlaceholder>
              )}
            </Typography>
          </LabelWidgetInner>
        </>
        {editMode && children}
      </LabelWidgetContainer>
    )
  }
)
