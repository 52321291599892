import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getStoredDataAsync, saveStoredDataAsync } from '../data-store/data-store-api'

type Meta = {
  name: string
  updatedAt: string
}

export const metaSliceInitialState: Meta = {
  name: '',
  updatedAt: '',
}

export const metaSlice = createSlice({
  name: 'meta',
  initialState: metaSliceInitialState,
  reducers: {
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getStoredDataAsync.fulfilled, (state, action) => {
      state.name = action.payload.name
      state.updatedAt = action.payload.updatedAt
    })
    .addCase(saveStoredDataAsync.fulfilled, (state, action) => {
      state.updatedAt = action.payload.data.updatedAt
    })
  }
})

export const {
  setName,
} = metaSlice.actions
