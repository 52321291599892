import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getStoredDataAsync } from '../data-store/data-store-api'

type GridDataState = Record<string, string>

export const gridDataSliceInitialState: GridDataState = {}

export const gridDataSlice = createSlice({
  name: 'gridData',
  initialState: gridDataSliceInitialState,
  reducers: {
    setData: (state, action: PayloadAction<{ id: string; type: string }>) => {
      state[action.payload.id] = action.payload.type
    },
    removeData: (state, action: PayloadAction<string>) => {
      delete state[action.payload]
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStoredDataAsync.fulfilled, (state, action) => {
      return action.payload.clientData.gridData
    })
  },
})

export const { setData, removeData } = gridDataSlice.actions
