import styled from 'styled-components'
import { Theme } from '../../theme/theme'
import { widgetBaseStyle } from '../widget-base-styles'

export type PreviousDataColor = 'neutral' | 'success' | 'danger'

export const ServiceLevelWidgetContainer = styled.div<{
  overrideTheme?: keyof Theme['widgets']
  editMode?: boolean
  isActive: boolean
}>`
  position: relative;
  ${widgetBaseStyle}
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 0px;

  img {
    width: 60px;

    @media (max-height: 830px) {
      width: 30px;
    }
  }
`

export const ServiceLevelNoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ServiceLevelNoData = styled.div`
  text-align: center;
  padding: 8px 0;
  font-size: 24px;
  color: ${(props) => props.theme.genericWidget.dataPoint};

  @media (max-height: 1000px) {
    font-size: 20px;
  }

  @media (max-width: 2000px) {
    font-size: 20px;
  }

  @media (max-width: 1700px) {
    font-size: 18px;
  }

  @media (max-width: 1400px) {
    font-size: 16px;
  }
`

export const ServiceLevelPreviousData = styled.div<{
  overrideTheme?: keyof Theme['widgets']
}>`
  display: flex;
  align-items: center;

  h4 {
    div {
      @media (max-height: 830px) {
        font-size: 20px;
        line-height: 23px;
      }
    }
  }

  .material-icons {
    color: ${(props) =>
      props.overrideTheme &&
      props.theme.widgets[props.overrideTheme]?.singleDataPointWidgetTheme
        ?.dataPointColor
        ? props.theme.widgets[props.overrideTheme].singleDataPointWidgetTheme
            ?.dataPointColor
        : props.theme.baseWidgets.singleDataPointWidget.dataPointColor};
  }
`
