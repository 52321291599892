import styled from 'styled-components'

export const GridEmptyContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const GridEmptyContainerInner = styled.div`
  box-sizing: border-box;
  width: 300px;
  text-align: center;
`

export const GridEmptyTitleContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 8px;
`

export const GridEmptyTitleOverrite = styled.div`
  color: ${(props) => props.theme.general.title};
`

export const GridEmptyTextOverrite = styled.div`
  color: ${(props) => props.theme.general.text};
`
