import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../hooks/redux-hooks'
import {
  selectDataPointChatAvgChatTime,
  selectDataPointChatAvgWaitTime,
  selectDataPointChatMaxWaitTime,
} from '../../lib/features/data-points/data-points-slice'
import { AggregatedTimesWidget } from '../aggregated-times-widget/aggregated-times-widget'

type Props = {
  id: string
  isActive: boolean
  onRemove?: () => void
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export const ChatTimesWidget = React.forwardRef<any, Props>(
  ({ id, isActive, onRemove, ...props }, ref) => {
    const { t } = useTranslation()
    const chatAvgWaitTime = useAppSelector((state) =>
      selectDataPointChatAvgWaitTime(state, id)
    )
    const chatAvgChatTime = useAppSelector((state) =>
      selectDataPointChatAvgChatTime(state, id)
    )
    const chatMaxWaitTime = useAppSelector((state) =>
      selectDataPointChatMaxWaitTime(state, id)
    )
    const label = useAppSelector(
      (state) => state.settings.widgetSettings[id]?.blockLabel
    )

    return (
      <AggregatedTimesWidget
        onRemove={onRemove}
        ref={ref}
        firstData={chatAvgWaitTime.live}
        seccondData={chatAvgChatTime.live}
        thirdData={chatMaxWaitTime.live}
        firstTitle={t('chatsAvgWaitTimeWidget.title')}
        seccondTitle={t('chatsAvgDurationWidget.title')}
        thirdTitle={t('chatsMaxWaitTimeWidget.title')}
        label={label}
        isActive={isActive}
        {...props}
      />
    )
  }
)
