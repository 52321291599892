import styled from 'styled-components'
import { Theme } from '../../theme/theme'
import { widgetBaseStyle } from '../widget-base-styles'

export const TicketsByChannelWidgetContainer = styled.div<{
  overrideTheme?: keyof Theme['widgets']
  editMode?: boolean
  isActive: boolean
}>`
  ${widgetBaseStyle};
  padding: 0;
`

export const TicketsByChannelWidgetContainerInner = styled.div`
  padding: 0 40px 40px 40px;
`

export const TicketsByChannelWidgetTitleContainer = styled.div`
  position: sticky;
  top: 0;
  padding: 40px 0 24px 0;
  background-color: ${(props) => props.theme.genericWidget.plate};
  z-index: 1;
`

export const TicketsByChannelWidgetGraphContainer = styled.div`
  display: grid;
  grid-gap: 64px;
  align-items: center;
  position: relative;

  padding-top: 40px;
  padding-bottom: 40px;
`

export const TicketsByChannelWidgetGraphBarContainer = styled.div`
  display: grid;
  grid-template-columns: 88px 1fr;
  grid-gap: 24px;
  align-items: center;
`

export const TicketsByChannelWidgetGraphBarTypeContainer = styled.div`
  text-align: end;
`

export const TicketsByChannelWidgetGraphBar = styled.div`
  display: flex;
  align-items: center;
`

export const TicketsByChannelWidgetGraphBarValue = styled.div<{
  size: number
  primary: boolean
}>`
  width: ${(props) => props.size}%;
  height: 48px;
  background: ${(props) =>
    props.primary
      ? props.theme.color.primary
      : props.theme.color.primaryLight4};
  border-radius: 8px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 12px;
  margin-right: 16px;
`

export const TicketsByChannelWidgetBackgroundLine = styled.div<{
  left: number
}>`
  width: 1px;
  height: 100%;
  background-color: ${(props) => props.theme.color.neutral2};
  position: absolute;
  left: calc(112px + ${(props) => props.left}%);
  z-index: -1;
`
