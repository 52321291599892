import { Widget } from './widgets-types'

export type OtherWidgetKeys = 'weatherWidget' | 'labelWidget'

export const otherWidgets: {
  [key in OtherWidgetKeys]: Widget
} = {
  weatherWidget: {
    i: 'weatherWidget',
    h: 1,
    w: 1,
    isResizable: false,
  },
  labelWidget: {
    i: 'labelWidget',
    h: 1,
    w: 1,
    isResizable: true,
  },
}
