import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchJson } from '../../fetch-json'
import { RootState } from '../../root-reducer'
import pick from 'lodash/pick'

type StoredClientData = {
  config: Partial<RootState['config']>
  layout: RootState['layout']
  settings: RootState['settings']
  gridData: RootState['gridData']
  theme: RootState['theme']
}

type ApiResponse = {
  name: string
  updatedAt: string
  clientData: StoredClientData
}

export const getStoredDataAsync = createAsyncThunk<
  ApiResponse,
  void,
  { state: RootState }
>('dataStore/getStoredData', async (_, { rejectWithValue }) => {
  const queryString = new URL(window.location.href)
  const urlParams = new URLSearchParams(queryString.search)
  const secret = urlParams.get('secret')

  const response = await fetchJson(`/client-data?secret=${secret}`)

  if (response.clientData) {
    return response
  } else {
    return rejectWithValue('No saved data')
  }
})

type SaveApiResponse = {
  success: boolean
  data: {
    updatedAt: string
  }
}

export const saveStoredDataAsync = createAsyncThunk<
  SaveApiResponse,
  void,
  { state: RootState }
>('dataStore/saveStoredData', async (_, { getState }) => {
  const queryString = new URL(window.location.href)
  const urlParams = new URLSearchParams(queryString.search)
  const secret = urlParams.get('secret')

  const state = getState()

  const saveState: StoredClientData = {
    config: pick(
      state.config,
      'phoneDefaultLayout',
      'chatDefaultLayout',
      'ticketDefaultLayout',
      'multiDefaultLayout'
    ),
    layout: state.layout,
    settings: state.settings,
    gridData: state.gridData,
    theme: state.theme,
  }

  if (secret) {
    return fetchJson(`/client-data?secret=${secret}`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        name: state.meta.name,
        clientData: saveState,
      }),
    })
  } else {
    return Promise.reject(new Error('Could not save client data, no api key'))
  }
})
