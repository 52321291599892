import { Action, combineReducers, ThunkAction } from '@reduxjs/toolkit'
import { configSlice } from './features/config/config-slice'
import { dataPointsSlice } from './features/data-points/data-points-slice'
import { draggingNewWidgetSlice } from './features/dragging-new-widget/dragging-new-widget-slice'
import { layoutSlice } from './features/layout/layout-slice'
import { themeSlice } from './features/theme/theme-slice'
import { settingsSlice } from './features/settings/settings-slice'
import { gridDataSlice } from './features/grid-data/grid-data-slice'
import { metaSlice } from './features/meta/meta-slice'
import { propertyPanelSlice } from './features/property-panel/property-panel-slice'
import { dataStoreSlice } from './features/data-store/data-store-slice'

export const rootReducer = combineReducers({
  theme: themeSlice.reducer,
  dataPoints: dataPointsSlice.reducer,
  config: configSlice.reducer,
  layout: layoutSlice.reducer,
  draggingNewWidget: draggingNewWidgetSlice.reducer,
  settings: settingsSlice.reducer,
  gridData: gridDataSlice.reducer,
  meta: metaSlice.reducer,
  propertyPanel: propertyPanelSlice.reducer,
  dataStore: dataStoreSlice.reducer
})

export type RootState = ReturnType<typeof rootReducer>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
