import i18n from '../../i18n/config'
import { Middleware } from '@reduxjs/toolkit'
import { RootState } from '../root-reducer'
import { getStoredDataAsync } from '../features/data-store/data-store-api'

export const languageStoreMiddleware: Middleware<void, RootState> =
  (storeApi) => (next) => (action) => {
    const result = next(action)

    if (result.type === getStoredDataAsync.fulfilled.type) {
      i18n.changeLanguage(storeApi.getState().settings.language)
    }
  }
