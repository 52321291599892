import styled from 'styled-components'

export const AutoScrollContainer = styled.div<{
  showScrollBar?: boolean
}>`
  height: 100%;
  overflow-y: ${(props) => (props.showScrollBar ? 'auto' : 'hidden')};
  overflow-x: hidden;

  ::-webkit-scrollbar {
    background: ${(props) => props.theme.genericWidget.plate};
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.color.neutral4};
    -webkit-border-radius: 8px;
  }

  ::-webkit-scrollbar-corner {
    background: ${(props) => props.theme.color.neutral4};
  }
`
