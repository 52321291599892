import { Select } from '@imbox/ui-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks'
import { configSlice } from '../../lib/features/config/config-slice'
import { setLanguage } from '../../lib/features/settings/settings-slice'

export const LanguageSelector: React.FC = () => {
  const { i18n, t } = useTranslation()
  const dispatch = useAppDispatch()
  const language = useAppSelector((state) => state.settings.language)
  const isUpdated = useAppSelector((state) => state.config.isUpdated)

  const setIsUpdated = () => {
    if (!isUpdated) {
      dispatch(configSlice.actions.setIsUpdated(true))
    }
  }

  React.useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const languageCode = event.target.value
    i18n.changeLanguage(languageCode)

    dispatch(setLanguage(languageCode))
    setIsUpdated()
  }

  return (
    <>
      <Select
        onChange={onChange}
        value={i18n.language}
        type="Primary"
        label={t('propertyPanel.languageLabel')}
      >
        <option value="en">English</option>
        <option value="sv">Swedish</option>
      </Select>
    </>
  )
}
