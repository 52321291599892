import React from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks'
import { useTranslation } from 'react-i18next'
import { setTicketCategoryIds } from '../../lib/features/settings/settings-slice'
import { MultiSelect, ValueLabelPair } from '../multi-select/multi-select'
import { useSetUpdated } from '../../hooks/use-set-updated'

export const TicketPropertyPanel: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const widgetId = useAppSelector((state) => state.propertyPanel.id)

  const selectedCategoryIds = useAppSelector(
    (state) => state.settings.widgetSettings[widgetId]?.ticketCategoryIds
  )
  const _ticketCategories = useAppSelector(
    (state) => state.settings.ticketCategories
  )

  useSetUpdated([selectedCategoryIds], widgetId)

  const ticketCategories: ValueLabelPair[] = _ticketCategories.map(
    (category) => {
      return {
        value: category.id,
        label: category.categoryName,
      }
    }
  )

  const onTicketCategoryChange = (option: any) => {
    dispatch(
      setTicketCategoryIds({
        widgetId,
        ticketCategoryIds: option,
      })
    )
  }

  return (
    <MultiSelect
      id="ticket-category"
      label={t('ticketsPropertyPanel.mailboxLabel')}
      isMulti
      placeholder={t('ticketsPropertyPanel.mailboxPlaceholder')}
      options={ticketCategories}
      value={selectedCategoryIds}
      onChange={onTicketCategoryChange}
    />
  )
}
