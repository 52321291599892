import { configureStore } from '@reduxjs/toolkit'
import { rootReducer } from './root-reducer'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import createSentryMiddleware from 'redux-sentry-middleware'
import { languageStoreMiddleware } from './middlewares/language-store-middleware'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://2d4b745dd685461c9ab194499548148c@sentry.imbox.io/10',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    normalizeDepth: 10,
  })
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(languageStoreMiddleware)
      .concat(createSentryMiddleware(Sentry as any)),
})

export type AppDispatch = typeof store.dispatch
