import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon, IconButton, Typography } from '@imbox/ui-components'
import {
  WidgetLabelContainer,
  WidgetRemoveContainer,
  WidgetTextOverrite,
} from '../widget-base-styles'
import {
  ServiceLevelWidgetContainer,
  ServiceLevelPreviousData,
  ServiceLevelNoDataContainer,
  ServiceLevelNoData,
} from './service-level-widget-styles'
import { useAppSelector } from '../../hooks/redux-hooks'
import {
  SingleDataPointWidgetHeader,
  WidgetSingleDataPointTextOverrite,
} from '../single-data-point-widget/single-data-point-widget-styles'

type Props = {
  onRemove?: () => void
  id: string
  waitTimes: {
    live: number[]
    previous: number[]
  }
  title: string
  isActive: boolean
}

const calculatePercentage = (
  waitTimes: number[],
  targetTime: string
): number | undefined => {
  if (waitTimes.length > 0) {
    const numOfCalls = waitTimes?.length
    const filteredWaitTimes = waitTimes?.filter(
      (w) => w <= parseFloat(targetTime) * 1000
    )
    const percentageResult = Math.round(
      (filteredWaitTimes?.length / numOfCalls) * 100
    )
    return percentageResult
  } else {
    return undefined
  }
}

export const ServiceLevelWidget = React.forwardRef<any, Props>(
  ({ onRemove, id, waitTimes, title, isActive, children, ...props }, ref) => {
    const { t } = useTranslation()
    const theme = useAppSelector((state) => state.theme)
    const editMode = useAppSelector((state) => state.config.editMode)
    const label = useAppSelector(
      (state) => state.settings.widgetSettings[id]?.blockLabel
    )
    const showPreviousData = useAppSelector(
      (state) => state.settings.showPreviousStats
    )
    const targetTime =
      useAppSelector(
        (state) => state.settings.widgetSettings[id]?.targetTime
      ) ?? ''
    const [previousData, setPreviousData] = useState<any>()
    const percentageResult = calculatePercentage(waitTimes.live, targetTime)

    useEffect(() => {
      if (showPreviousData) {
        setPreviousData(calculatePercentage(waitTimes.previous, targetTime))
      }
    }, [waitTimes])

    return (
      <ServiceLevelWidgetContainer
        editMode={editMode}
        isActive={isActive}
        ref={ref}
        {...props}
      >
        {editMode && label && (
          <WidgetLabelContainer>
            <Typography type="BodySmallRegular">{label}</Typography>
          </WidgetLabelContainer>
        )}
        {onRemove && (
          <WidgetRemoveContainer>
            <IconButton size="small" onClick={onRemove} type="Secondary">
              close
            </IconButton>
          </WidgetRemoveContainer>
        )}

        {percentageResult === undefined ? (
          <ServiceLevelNoDataContainer>
            <img src={`/assets/empty-state-${theme}.svg`} alt={''} />
            <Typography type="BodyLargeSemiBold">
              <ServiceLevelNoData>
                {t('callServiceLevelWidget.noData')}
              </ServiceLevelNoData>
            </Typography>
          </ServiceLevelNoDataContainer>
        ) : (
          <>
            <Typography
              type="Heading1"
              custom={{
                lineHeight: 120,
                size: 104,
                style: 'normal',
                weight: 'bold',
              }}
            >
              <SingleDataPointWidgetHeader showPreviousData={showPreviousData}>
                {percentageResult}%
              </SingleDataPointWidgetHeader>
            </Typography>

            {showPreviousData && (
              <ServiceLevelPreviousData>
                {previousData >= 0 ? (
                  <>
                    {previousData === percentageResult ? (
                      <Icon size="large">arrow_forward</Icon>
                    ) : (
                      <>
                        <Icon size="large">
                          {percentageResult && percentageResult > previousData
                            ? 'arrow_upward'
                            : 'arrow_downward'}
                        </Icon>
                        <Typography type="Heading4">
                          <WidgetSingleDataPointTextOverrite>
                            {previousData}%
                          </WidgetSingleDataPointTextOverrite>
                        </Typography>
                      </>
                    )}
                  </>
                ) : (
                  <Typography type="Heading4">
                    <WidgetSingleDataPointTextOverrite>
                      -
                    </WidgetSingleDataPointTextOverrite>
                  </Typography>
                )}
              </ServiceLevelPreviousData>
            )}
          </>
        )}

        <Typography type="BodyLargeSemiBold">
          <WidgetTextOverrite>{title}</WidgetTextOverrite>
        </Typography>

        {editMode && children}
      </ServiceLevelWidgetContainer>
    )
  }
)
