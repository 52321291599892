import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks'
import GridLayout from 'react-grid-layout'
import { Typography } from '@imbox/ui-components'
import {
  BoardContainer,
  boardMediaWidth,
  ImboxBranding,
  ImboxLogo,
} from './board-styles'
import { layoutSlice } from '../../lib/features/layout/layout-slice'
import { widgets } from '../../lib/widgets/widgets'
import { WidgetKeys } from '../../lib/widgets/widgets-types'
import { draggingNewWidgetSlice } from '../../lib/features/dragging-new-widget/dragging-new-widget-slice'
import { configSlice } from '../../lib/features/config/config-slice'
import { GridEmpty } from '../grid-empty/grid-empty'
import { renderWidgets } from '../render-widgets/render-widgets'
import {
  removeData,
  setData,
} from '../../lib/features/grid-data/grid-data-slice'
import { getDataPointsAsync } from '../../lib/features/data-points/data-points-api'
import {
  closePropertyPanel,
  openPropertyPanel,
} from '../../lib/features/property-panel/property-panel-slice'
import isEqual from 'lodash/isEqual'

export const Board: React.FC = () => {
  const dispatch = useAppDispatch()
  const boardContainerRef = useRef<HTMLDivElement>(null)
  const editMode = useAppSelector((state) => state.config.editMode)
  const _storeLayout = useAppSelector((state) => state.layout)
  const draggingNewWidget = useAppSelector((state) => state.draggingNewWidget)
  const gridData = useAppSelector((state) => state.gridData)
  const propertyPanel = useAppSelector((state) => state.propertyPanel)
  const isUpdated = useAppSelector((state) => state.config.isUpdated)
  const storedLayout = useAppSelector((state) => state.config.storedLayout)
  const theme = useAppSelector((state) => state.theme)
  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)
  const [layout, setLayout] = useState<GridLayout.Layout[]>(_storeLayout)
  const [isDragging, setIsDragging] = useState(false)
  const [isResizing, setIsResizing] = useState(false)

  useEffect(() => {
    const onWindowResize = () => {
      setHeight(window.innerHeight)
      setWidth(window.innerWidth)
    }

    onWindowResize()
    /**
     * window.addEventListener or window.onresize might not be suported i tv browsers
     * so we run an interval every minute
     */
    const id = setInterval(() => {
      onWindowResize()
    }, 10000) // 10 seconds

    return () => clearInterval(id)
  }, [])

  useEffect(() => {
    const updatedLayout = layout.map((a) => ({
      ...a,
      isDraggable: editMode,
      isResizable: editMode
        ? widgets[gridData[a.i] as WidgetKeys]?.isResizable ?? false
        : false,
    }))

    setLayout(updatedLayout)
    dispatch(layoutSlice.actions.setLayout(updatedLayout))
  }, [editMode])

  useEffect(() => {
    setLayout(_storeLayout)
  }, [_storeLayout])

  const onLayoutChange = (layout: GridLayout.Layout[]) => {
    const updateLayout = layout.map((l) => {
      if (l.i === '__dropping-elem__') {
        return {
          ...l,
          h: draggingNewWidget.h,
          w: draggingNewWidget.w,
          minH: draggingNewWidget.minH,
          minW: draggingNewWidget.minW,
          isResizable: false,
        }
      }

      return l
    })
    setLayout(updateLayout)
    dispatch(layoutSlice.actions.setLayout(updateLayout))

    if (!isEqual(storedLayout, _storeLayout) && !isUpdated) {
      dispatch(configSlice.actions.setIsUpdated(true))
    }
  }

  const onDrop = (
    layout: GridLayout.Layout[],
    item: GridLayout.Layout,
    event: any
  ) => {
    setLayout(
      layout.map((l) => {
        if (l.i === '__dropping-elem__') {
          return {
            ...l,
            ...draggingNewWidget,
          }
        }

        return l
      })
    )

    if (draggingNewWidget.type) {
      dispatch(
        openPropertyPanel({
          id: draggingNewWidget.i,
          type: draggingNewWidget.type as WidgetKeys,
        })
      )
    }

    dispatch(
      setData({
        id: draggingNewWidget.i,
        type: draggingNewWidget.type ?? '',
      })
    )
    dispatch(
      draggingNewWidgetSlice.actions.setDraggingNewWidget({
        i: '',
        w: 0,
        h: 0,
      })
    )
    dispatch(configSlice.actions.setUsingCustomLayout())
    dispatch(getDataPointsAsync())
    setIsDragging(false)
  }

  const getScale = (): number => {
    if (!editMode) {
      return 1
    }

    const mediaMatch = boardMediaWidth.find(
      ([media]) => window.matchMedia(media).matches
    )
    if (mediaMatch) {
      return mediaMatch[1]
    }

    return 0.8
  }

  const onRemoveWidget = (id: string) => {
    const updatedLayout = layout.filter((l) => l.i !== id)
    setLayout(updatedLayout)
    dispatch(layoutSlice.actions.setLayout(updatedLayout))
    dispatch(configSlice.actions.setUsingCustomLayout())
    dispatch(removeData(id))

    if (!isEqual(storedLayout, updatedLayout) && !isUpdated) {
      dispatch(configSlice.actions.setIsUpdated(true))
    }
  }

  const onDrag = () => {
    setIsDragging(true)
    dispatch(configSlice.actions.setUsingCustomLayout())
  }

  const onDragStop = () => {
    // setTimeout is necessary for setIsDragging to be fired after click event
    setTimeout(() => {
      setIsDragging(false)
    }, 0)
  }

  const onResize = () => {
    setIsResizing(true)
    dispatch(configSlice.actions.setUsingCustomLayout())
  }

  const onResizeStop = () => {
    // setTimeout is necessary for setIsResizing to be fired after click event
    setTimeout(() => {
      setIsResizing(false)
    }, 0)
  }

  const onClick = (
    id: string,
    type: WidgetKeys,
    e: React.MouseEvent<HTMLElement>
  ) => {
    const target = e.target as HTMLElement
    if (target.innerText === 'close') {
      if (propertyPanel.id === id) {
        dispatch(closePropertyPanel())
      }
      return
    }

    if (!isDragging && !isResizing) {
      if (propertyPanel.id !== id) {
        dispatch(openPropertyPanel({ id, type }))
      }
    }
  }

  return (
    <BoardContainer editMode={editMode} ref={boardContainerRef}>
      {layout.length > 0 || draggingNewWidget.i !== '' ? (
        <>
          <GridLayout
            className="layout"
            layout={layout}
            cols={6}
            rowHeight={height / 5 - 40}
            width={width}
            onLayoutChange={onLayoutChange}
            onResize={onResize}
            onResizeStop={onResizeStop}
            onDrag={onDrag}
            onDragStop={onDragStop}
            onDrop={onDrop}
            transformScale={getScale()}
            isDroppable={true}
            isBounded={true}
            verticalCompact={true}
            margin={[32, 32]}
          >
            {layout.map((data) => {
              const widget = widgets[gridData[data.i] as WidgetKeys]
              const id = data.i
              const type = gridData[data.i]
              const isActive = propertyPanel.isOpen && propertyPanel.id === id

              if (widget) {
                return renderWidgets(
                  widget,
                  editMode,
                  isActive,
                  onRemoveWidget,
                  onClick,
                  id,
                  type
                )
              }

              return <div key={data.i}></div>
            })}
          </GridLayout>
          <ImboxBranding>
            <Typography type="BodySmallSemiBold" color="neutral4">
              This wallboard is created by{' '}
            </Typography>
            <ImboxLogo src={`/assets/imbox-logo-grey-${theme}.svg`} alt="" />
          </ImboxBranding>
        </>
      ) : (
        <GridEmpty />
      )}
    </BoardContainer>
  )
}
