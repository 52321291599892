import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/browser'
import { useTranslation } from 'react-i18next'
import { Typography } from '@imbox/ui-components'
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks'
import { configSlice } from '../../lib/features/config/config-slice'
import { gridDataSlice } from '../../lib/features/grid-data/grid-data-slice'
import { layoutSlice } from '../../lib/features/layout/layout-slice'
import { closePropertyPanel } from '../../lib/features/property-panel/property-panel-slice'
import { predefinedChatLayout } from '../../lib/predefined-layouts/predefined-chat-layouts'
import { predefinedMultiLayout } from '../../lib/predefined-layouts/predefined-multi-layout'
import { predefinedPhoneLayout } from '../../lib/predefined-layouts/predefined-phone-layout'
import { predefinedTicketLayout } from '../../lib/predefined-layouts/predefined-ticket-layout'
import { chatWidgets } from '../../lib/widgets/chat-widgets'
import { otherWidgets } from '../../lib/widgets/other-widgets'
import { phoneWidgets } from '../../lib/widgets/phone-widgets'
import { ticketWidgets } from '../../lib/widgets/ticket-widgets'
import { SidebarListItem } from './sidebar-list-item'
import { Button } from '../button/button'
import {
  SidbarButtonsContainer,
  SidbarListTitle,
  SidebarContainer,
} from './sidebar-styles'
import { getDataPointsAsync } from '../../lib/features/data-points/data-points-api'

export const Sidebar: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const editMode = useAppSelector((state) => state.config.editMode)
  const propertyPanel = useAppSelector((state) => state.propertyPanel)
  const isDefaultPhoneLayout = useAppSelector(
    (state) => state.config.phoneDefaultLayout
  )
  const isDefaultChatLayout = useAppSelector(
    (state) => state.config.chatDefaultLayout
  )
  const isDefaultTicketLayout = useAppSelector(
    (state) => state.config.ticketDefaultLayout
  )
  const isDefaultMultiLayout = useAppSelector(
    (state) => state.config.multiDefaultLayout
  )
  const layout = useAppSelector((state) => state.layout)
  const isUpdated = useAppSelector((state) => state.config.isUpdated)

  const [products, setProducts] = useState<Array<{ name: string }>>([])

  const hasAccessToProduct = (name: string): boolean => {
    return products.some((product) => product.name === name)
  }

  useEffect(() => {
    const queryString = new URL(window.location.href)
    const urlParams = new URLSearchParams(queryString.search)
    const secret = urlParams.get('secret')

    const fetchProducts = async () => {
      try {
        const response = await fetch(`/product-labels?secret=${secret}`)
        if (!response.ok) {
          throw new Error(response.statusText)
        }
        const products = await response.json()
        setProducts(products.productLabels)
      } catch (err) {
        console.error(err)
        Sentry.captureException(err)
      }
    }
    fetchProducts()
  }, [])

  const onClearView = () => {
    dispatch(configSlice.actions.setUsingCustomLayout())
    dispatch(layoutSlice.actions.setLayout([]))
    layout.map((l) => {
      return dispatch(gridDataSlice.actions.removeData(l.i))
    })
    if (propertyPanel.isOpen) {
      dispatch(closePropertyPanel())
    }

    if (!isUpdated) {
      dispatch(configSlice.actions.setIsUpdated(true))
    }
  }

  const onUsePhoneDefaultLayout = () => {
    if (propertyPanel.isOpen) {
      dispatch(closePropertyPanel())
    }
    dispatch(configSlice.actions.setUsePhoneDefaultLayout())
    dispatch(layoutSlice.actions.setLayout(predefinedPhoneLayout))
    layout.map((l) => {
      return dispatch(gridDataSlice.actions.removeData(l.i))
    })
    predefinedPhoneLayout.map((a) => {
      return dispatch(
        gridDataSlice.actions.setData({
          id: a.i,
          type: a.i,
        })
      )
    })
    dispatch(getDataPointsAsync())
  }

  const onUseChatDefaultLayout = () => {
    if (propertyPanel.isOpen) {
      dispatch(closePropertyPanel())
    }
    dispatch(configSlice.actions.setUseChatDefaultLayout())
    dispatch(layoutSlice.actions.setLayout(predefinedChatLayout))
    layout.map((l) => {
      return dispatch(gridDataSlice.actions.removeData(l.i))
    })
    predefinedChatLayout.map((a) => {
      return dispatch(
        gridDataSlice.actions.setData({
          id: a.i,
          type: a.i,
        })
      )
    })
    dispatch(getDataPointsAsync())
  }

  const onUseTicketDefaultLayout = () => {
    if (propertyPanel.isOpen) {
      dispatch(closePropertyPanel())
    }
    dispatch(configSlice.actions.setUseTicketDefaultLayout())
    dispatch(layoutSlice.actions.setLayout(predefinedTicketLayout))
    layout.map((l) => {
      return dispatch(gridDataSlice.actions.removeData(l.i))
    })
    predefinedTicketLayout.map((a) => {
      return dispatch(
        gridDataSlice.actions.setData({
          id: a.i,
          type: a.i,
        })
      )
    })
    dispatch(getDataPointsAsync())
  }

  const onUseMultiDefaultLayout = () => {
    if (propertyPanel.isOpen) {
      dispatch(closePropertyPanel())
    }
    dispatch(configSlice.actions.setUseMultiDefaultLayout())
    dispatch(layoutSlice.actions.setLayout(predefinedMultiLayout))
    layout.map((l) => {
      return dispatch(gridDataSlice.actions.removeData(l.i))
    })
    predefinedMultiLayout.map((a) => {
      return dispatch(
        gridDataSlice.actions.setData({
          id: a.i,
          type: a.i,
        })
      )
    })
    dispatch(getDataPointsAsync())
  }

  return (
    <>
      <SidebarContainer editMode={editMode}>
        <Button onClick={onClearView}>
          <Typography type="BodyMediumSemiBold">
            {t('sideBar.clear')}
          </Typography>
        </Button>
        <SidbarButtonsContainer>
          <Typography type="BodyMediumMedium">
            {t('sideBar.templates')}
          </Typography>
          {hasAccessToProduct('chat') && (
            <Button
              onClick={onUseChatDefaultLayout}
              icon="chat"
              isActive={isDefaultChatLayout}
            >
              <Typography type="BodyMediumMedium">
                {t('sideBar.chatButton')}
              </Typography>
            </Button>
          )}
          {hasAccessToProduct('call') && (
            <Button
              onClick={onUsePhoneDefaultLayout}
              icon="phone"
              isActive={isDefaultPhoneLayout}
            >
              <Typography type="BodyMediumMedium">
                {t('sideBar.phoneButton')}
              </Typography>
            </Button>
          )}
          {hasAccessToProduct('tickets') && (
            <Button
              onClick={onUseTicketDefaultLayout}
              icon="send"
              isActive={isDefaultTicketLayout}
            >
              <Typography type="BodyMediumMedium">
                {t('sideBar.ticketButton')}
              </Typography>
            </Button>
          )}
          {hasAccessToProduct('chat') &&
            hasAccessToProduct('tickets') &&
            hasAccessToProduct('call') && (
              <Button
                onClick={onUseMultiDefaultLayout}
                icon="widgets"
                isActive={isDefaultMultiLayout}
              >
                <Typography type="BodyMediumMedium">
                  {t('sideBar.multiLayoutButton')}
                </Typography>
              </Button>
            )}
        </SidbarButtonsContainer>

        {hasAccessToProduct('chat') && (
          <>
            <SidbarListTitle type="BodyMediumMedium">
              {t('sideBar.chatWidgets')}
            </SidbarListTitle>
            {Object.values(chatWidgets).map((widget) => {
              return (
                <SidebarListItem widget={widget} key={widget.i} icon="chat" />
              )
            })}
          </>
        )}

        {hasAccessToProduct('call') && (
          <>
            <SidbarListTitle type="BodyMediumMedium">
              {t('sideBar.phoneWidgets')}
            </SidbarListTitle>
            {Object.values(phoneWidgets).map((widget) => {
              return (
                <SidebarListItem
                  widget={widget}
                  key={widget.i}
                  icon="local_phone"
                />
              )
            })}
          </>
        )}

        {hasAccessToProduct('tickets') && (
          <>
            <SidbarListTitle type="BodyMediumMedium">
              {t('sideBar.ticketWidgets')}
            </SidbarListTitle>
            {Object.values(ticketWidgets).map((widget) => {
              return (
                <SidebarListItem widget={widget} key={widget.i} icon="send" />
              )
            })}
          </>
        )}

        <SidbarListTitle type="BodyMediumMedium">
          {t('sideBar.otherWidgets')}
        </SidbarListTitle>
        {Object.values(otherWidgets).map((widget) => {
          return (
            <SidebarListItem
              widget={widget}
              key={widget.i}
              icon={widget.i === 'weatherWidget' ? 'wb_sunny' : 'text_fields'}
            />
          )
        })}
      </SidebarContainer>
    </>
  )
}
