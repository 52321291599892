import { createSlice } from '@reduxjs/toolkit'
import { getStoredDataAsync } from './data-store-api'

type DataStore = {
  status: 'success' | 'error' | 'loading' | 'idle'
}

export const dataStoreSliceInitialState: DataStore = {
  status: 'idle',
}

export const dataStoreSlice = createSlice({
  name: 'dataStore',
  initialState: dataStoreSliceInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStoredDataAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getStoredDataAsync.fulfilled, (state) => {
        state.status = 'success'
      })
  },
})
