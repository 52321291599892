import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../../root-reducer'
import { chatWidgets } from '../../widgets/chat-widgets'
import { otherWidgets } from '../../widgets/other-widgets'
import { phoneWidgets } from '../../widgets/phone-widgets'
import { ticketWidgets } from '../../widgets/ticket-widgets'
import { Call, Chat } from './data-points-slice'

type CallsApiResponse = {
  live: Call
  previous: Call
}

type TicketsApiResponse = RootState['dataPoints']['points']['tickets']

type ChatApiResponse = {
  live: Chat
  previous: Chat
}

type ApiResponse = {
  calls?: CallsApiResponse
  tickets?: TicketsApiResponse
  chats?: ChatApiResponse
}

function fetchDataPoints(
  secret: string,
  fetchPhone: boolean,
  fetchTickets: boolean,
  fetchChat: boolean
) {
  return new Promise<ApiResponse>((resolve, reject) => {
    let urlParams = `since=${new Date().getHours()}&includePrevious=true`
    if (fetchPhone) {
      urlParams += `&phone=${fetchPhone}&groupPhoneQueue=true`
    }
    if (fetchChat) {
      urlParams += `&chat=${fetchChat}`
    }
    if (fetchTickets) {
      urlParams += `&tickets=${fetchTickets}`
    }
    urlParams += `&secret=${secret}`

    return fetch(`/livestats?${urlParams}`)
      .then((response) => response.json())
      .then((d) => resolve(d))
      .catch((e) => reject(e))
  })
}

const phoneWidgetKeys = Object.keys(phoneWidgets)
const chatWidgetKeys = Object.keys(chatWidgets)
const ticketWidgetKeys = Object.keys(ticketWidgets)
export const getDataPointsAsync = createAsyncThunk<
  ApiResponse,
  void,
  { state: RootState }
>(
  'dataPoints/getDataPoints',
  async (_, { getState }) => {
    const state = getState()

    const queryString = new URL(window.location.href)
    const urlParams = new URLSearchParams(queryString.search)
    const secret = urlParams.get('secret') ?? ''

    const fetchPhone = Boolean(
      Object.values(state.gridData).find((data) =>
        phoneWidgetKeys.includes(data)
      )
    )
    const fetchChat = Boolean(
      Object.values(state.gridData).find((data) =>
        chatWidgetKeys.includes(data)
      )
    )

    const fetchTickets = Boolean(
      Object.values(state.gridData).find((data) =>
        ticketWidgetKeys.includes(data)
      )
    )

    const response = await fetchDataPoints(
      secret,
      fetchPhone,
      fetchTickets,
      fetchChat
    )
    return response
  },

  {
    condition: (_, { getState }) => {
      const { dataPoints, layout, gridData } = getState()
      const fetchStatus = dataPoints.status.fetch

      const otherWidgetKeys = Object.keys(otherWidgets)
      const otherWidgetsArray = Object.values(gridData).filter((data) =>
        otherWidgetKeys.includes(data)
      )
      const isOnlyOtherWidgets =
        layout.length !== 0 && otherWidgetsArray.length === layout.length

      if (
        fetchStatus === 'loading' ||
        layout.length === 0 ||
        isOnlyOtherWidgets
      ) {
        return false
      }
    },
  }
)
