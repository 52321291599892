import React from 'react'
import Select, { Props as SelectProps } from 'react-select'
import styled from 'styled-components'

const FormGroup = styled.div`
  .standard__control {
    min-height: 40px;
    border-color: ${(props) => props.theme.color.neutral3};
  }
  .standard__value-container {
    padding: 2px 14px;
  }
  .standard__placeholder {
    font-size: 14px;
  }
`

const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
  font-family: 'Inter';
`

export type ValueLabelPair = {
  value: string | number
  label: string
}

export interface Props extends SelectProps {
  error?: boolean
  helperText?: string
  label?: string
}

export const MultiSelect: React.FC<Props> = ({
  id,
  label,
  value,
  onChange,
  options,
  placeholder,
}) => {
  return (
    <FormGroup>
      {label && <Label htmlFor={id}>{label}</Label>}
      <Select
        inputId={id}
        isMulti
        placeholder={placeholder}
        name="multi-select"
        classNamePrefix="standard"
        options={options}
        value={value}
        onChange={onChange}
      />
    </FormGroup>
  )
}
