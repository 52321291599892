import { Select } from '@imbox/ui-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks'
import { configSlice } from '../lib/features/config/config-slice'
import { themeSlice } from '../lib/features/theme/theme-slice'
import { AvailableThemes, themes } from '../theme/theme'

export const ThemeSelector: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const activeTheme = useAppSelector((state) => state.theme)
  const isUpdated = useAppSelector((state) => state.config.isUpdated)

  const setIsUpdated = () => {
    if (!isUpdated) {
      dispatch(configSlice.actions.setIsUpdated(true))
    }
  }

  const onThemeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value as AvailableThemes
    dispatch(themeSlice.actions.setTheme(value))
    setIsUpdated()
  }

  return (
    <Select
      value={activeTheme}
      onChange={onThemeSelect}
      label={t('propertyPanel.themeLabel')}
    >
      {Object.keys(themes).map((key) => {
        const label = key[0].toUpperCase() + key.slice(1)
        return (
          <option value={key} key={key}>
            {label}
          </option>
        )
      })}
    </Select>
  )
}
