import * as Sentry from '@sentry/browser'
import { getUrlParams } from './get-url-parms'

export const windowRefresh = () => {
  const loadedAt = Date.now()
  const isEditMode = getUrlParams().editMode

  if (isEditMode) return

  setInterval(() => {
    try {
      if (loadedAt > Date.now() - 1000 * 60 * 60 * 24) {
        window.location.reload()
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }, 1000 * 60 * 60)
}
