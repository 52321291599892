import { Typography } from '@imbox/ui-components'
import styled, { css } from 'styled-components'

export const SidebarContainer = styled.div<{
  editMode: boolean
}>`
  display: none;
  overflow-y: auto;
  padding: 24px 16px;

  @supports (display: grid) {
    display: initial;
    grid-area: sidebar;
    width: 300px;
    height: calc(100% - 64px);
  }

  z-index: 1;
  background-color: ${(props) => props.theme.color.white};
  box-shadow: ${(props) => props.theme.shadow.mediumStroke};

  transition: margin-left 0.2s ease-out 0s;

  ${(props) =>
    props.editMode
      ? css``
      : css`
          margin-left: -301px;
        `}
`

export const SidbarListItem = styled.div`
  border-radius: 8px;
  padding: 10px 16px;
  margin-top: 8px;
  cursor: grab;
  border: 1px solid ${(props) => props.theme.color.neutral2};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  display: grid;
  grid-template-columns: 16px 80% 16px;
  grid-gap: 8px;

  &:hover {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  }
`

export const SidebarListItemContent = styled.div``

export const IconLeftContainer = styled.div`
  margin-top: 1px;
`

export const IconRightContainer = styled.div`
  display: flex;
  align-items: center;
`

export const SidbarButtonsContainer = styled.div`
  display: grid;
  grid-gap: 8px;
  margin-bottom: 24px;
  margin-top: 24px;
`

export const SidbarListTitle = styled(Typography)`
  margin-top: 16px;
`
