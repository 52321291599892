export function getUrlParams(): {
  editMode: boolean
} {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  const editMode = (urlParams.get('editMode') ?? 'false') === 'true'

  return {
    editMode,
  }
}
