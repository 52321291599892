import styled from 'styled-components'
import { Theme } from '../../theme/theme'
import {
  widgetBaseStyle,
  WidgetDataPointTextOverrite,
} from '../widget-base-styles'

export const WeatherWidgetContainer = styled.div<{
  overrideTheme?: keyof Theme['widgets']
  editMode?: boolean
  isActive: boolean
}>`
  ${widgetBaseStyle};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 60px;
  }
`

export const WeatherWidgetInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 120px;

  @media (max-height: 1000px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 90px;
  }

  @media (max-height: 830px) {
    min-height: 80px;
  }
`

export const WeatherWidgetHeader = styled(WidgetDataPointTextOverrite)`
  margin-left: 0;
  font-size: 60px;
  line-height: 60px;

  @media (max-height: 1000px) {
    margin-left: 12px;
    font-size: 80px;
  }
  
  @media (max-height: 830px) {
    font-size: 70px;
  }
  
  @media (max-width: 1400px) {
    font-size: 60px;
  }
`
