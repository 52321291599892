import { Theme } from './theme'
import { uiComponentsTheme } from '@imbox/ui-components'

export const darkTheme: Theme = {
  ...uiComponentsTheme,

  board: {
    plate: '#121212',
  },

  genericWidget: {
    plate: '#1E1E1E',
    dataPoint: uiComponentsTheme.color.white,
    text: uiComponentsTheme.color.neutral4,
    divider: '#2D2D2D',
  },

  widgets: {
    callsCompleted: {
      singleDataPointWidgetTheme: {
        dataPointColor: uiComponentsTheme.color.neutral1,
        title: uiComponentsTheme.color.neutral1,
        plate: uiComponentsTheme.color.infoDark,
      },
    },
    callsMissed: {
      singleDataPointWidgetTheme: {
        dataPointColor: uiComponentsTheme.color.neutral1,
        title: uiComponentsTheme.color.neutral1,
        plate: uiComponentsTheme.color.dangerDark,
      },
    },

    callsOutgoing: {
      singleDataPointWidgetTheme: {
        dataPointColor: uiComponentsTheme.color.neutral1,
        title: uiComponentsTheme.color.neutral1,
        plate: uiComponentsTheme.color.warningDark,
      },
    },

    callsIncoming: {
      singleDataPointWidgetTheme: {
        dataPointColor: uiComponentsTheme.color.neutral1,
        title: uiComponentsTheme.color.neutral1,
        plate: uiComponentsTheme.color.successDark,
      },
    },

    callQueues: {
      headerText: uiComponentsTheme.color.neutral4,
      headerBorder: '#1E1E1E',
      cellBorder: '#1E1E1E',
      cellText: uiComponentsTheme.color.white,
      cellPlate: '#1E1E1E',
      callPlateHighEmphasis: '#2D2D2D',
    },

    chatsMissedWidget: {
      singleDataPointWidgetTheme: {
        dataPointColor: uiComponentsTheme.color.neutral1,
        title: uiComponentsTheme.color.neutral1,
        plate: uiComponentsTheme.color.dangerDark,
      },
    },
    chatsTotalWidget: {
      singleDataPointWidgetTheme: {
        dataPointColor: uiComponentsTheme.color.neutral1,
        title: uiComponentsTheme.color.neutral1,
        plate: uiComponentsTheme.color.infoDark,
      },
    },
    chatsAnsweredWidget: {
      singleDataPointWidgetTheme: {
        dataPointColor: uiComponentsTheme.color.neutral1,
        title: uiComponentsTheme.color.neutral1,
        plate: uiComponentsTheme.color.successDark,
      },
    },
    serviceLevelWidget: {
      previousDataSuccess: uiComponentsTheme.color.successDark,
      previousDataDanger: uiComponentsTheme.color.dangerDark
    }
  },

  baseWidgets: {
    singleDataPointWidget: {
      dataPointColor: uiComponentsTheme.color.neutral1,
      title: uiComponentsTheme.color.neutral4,
    },
  },

  general: {
    title: uiComponentsTheme.color.neutral3,
    text: uiComponentsTheme.color.neutral5
  }
}
