import React from 'react'
import * as Sentry from '@sentry/browser'

type TopLevelErrorBoundaryState = {
  error: boolean
}

export class TopLevelErrorBoundary extends React.Component<
  Record<string, unknown>,
  TopLevelErrorBoundaryState
> {
  state = {
    error: false,
  }

  static getDerivedStateFromError() {
    return { error: true }
  }

  componentDidCatch(error: Error) {
    console.error(error)
    Sentry.captureException(error)
  }

  render() {
    if (this.state.error) {
      return (
        <>
          <h1>Error occurred</h1>
        </>
      )
    }

    return this.props.children
  }
}
