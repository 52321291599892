import { darkTheme } from './dark-theme'
import { lightTheme } from './light-theme'
import { uiComponentsTheme } from '@imbox/ui-components'

type SingleDataPointWidgetTheme = {
  dataPointColor?: string
  title?: string
  subtitle?: string
  plate?: string
  icon?: string
}

export type Theme = {
  board: {
    plate: string
  }

  genericWidget: {
    plate: string
    dataPoint: string
    text: string
    divider: string
  }

  widgets: {
    callsCompleted: {
      singleDataPointWidgetTheme?: SingleDataPointWidgetTheme
    }

    callsMissed: {
      singleDataPointWidgetTheme?: SingleDataPointWidgetTheme
    }

    callsOutgoing: {
      singleDataPointWidgetTheme?: SingleDataPointWidgetTheme
    }

    callQueues: {
      singleDataPointWidgetTheme?: SingleDataPointWidgetTheme
      headerText: string
      headerBorder?: string
      cellText?: string
      cellPlate?: string
      callPlateHighEmphasis?: string
      cellBorder?: string
    }

    callsIncoming: {
      singleDataPointWidgetTheme?: SingleDataPointWidgetTheme
    }

    chatsMissedWidget: {
      singleDataPointWidgetTheme?: SingleDataPointWidgetTheme
    }
    chatsTotalWidget: {
      singleDataPointWidgetTheme?: SingleDataPointWidgetTheme
    }
    chatsAnsweredWidget: {
      singleDataPointWidgetTheme?: SingleDataPointWidgetTheme
    }
    serviceLevelWidget: {
      singleDataPointWidgetTheme?: SingleDataPointWidgetTheme
      previousDataSuccess: string,
      previousDataDanger: string
    }
  }

  baseWidgets: {
    singleDataPointWidget: SingleDataPointWidgetTheme
  }

  general: {
    title: string
    text: string
  }
} & typeof uiComponentsTheme

export type AvailableThemes = 'dark' | 'light'
export const themes: {
  [key in AvailableThemes]: Theme
} = {
  dark: darkTheme,
  light: lightTheme,
}
