import styled from 'styled-components'
import { Theme } from '../../theme/theme'
import {
  widgetBaseStyle,
  WidgetDataPointTextOverrite,
} from '../widget-base-styles'

export const SplittedStatsWidgetContainer = styled.div<{
  overrideTheme?: keyof Theme['widgets']
  editMode?: boolean
  isActive: boolean
}>`
  ${widgetBaseStyle};

  display: flex;
  flex-direction: column;

  @supports (display: grid) {
    display: grid;
    grid-template-areas:
      'top'
      'divider'
      'bottom';
    grid-template-rows: 2fr 1px 1fr;
  }

  padding: 0;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;

  @media (max-height: 1140px) {
    padding-bottom: 20px;
  }
`

export const SplittedStatsWidgetTopContainer = styled.div`
  grid-area: top;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 2;
`

export const SplittedStatsWidgetBottomContainer = styled.div`
  grid-area: bottom;
  display: flex;
  justify-content: space-around;
  margin-top: 40px;

  @media (max-height: 1140px) {
    margin-top: 10px;
  }
`

export const SplittedStatsWidgetBottomDataContainer = styled.div`
  grid-area: bottom;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const SplittedStatsWidgetDivider = styled.div`
  margin-left: -40px;
  margin-right: -40px;
  border: 1px solid ${(props) => props.theme.genericWidget.divider};
`

export const SplittedStatsWidgetDataPointTextOverrite = styled(
  WidgetDataPointTextOverrite
)`
  @media (max-height: 1350px) {
    line-height: 167px;
    font-size: 132px;
  }

  @media (max-height: 1040px) {
    line-height: 130px;
    font-size: 110px;
  }

  @media (max-height: 900px) {
    line-height: 110px;
    font-size: 90px;
  }
`
