import intervalToDuration from 'date-fns/intervalToDuration'

export function formatDuration(time: number): string {
  const duration = intervalToDuration({ start: 0, end: time })

  const hours =
    (duration?.hours ?? 0) > 9 ? duration.hours : `0${duration.hours}`
  const minutes =
    (duration?.minutes ?? 0) > 9 ? duration.minutes : `0${duration.minutes}`
  const seconds =
    (duration?.seconds ?? 0) > 9 ? duration.seconds : `0${duration.seconds}`

  return `${hours}:${minutes}:${seconds}`
}
