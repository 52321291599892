import { Icon, IconButton, Typography } from '@imbox/ui-components'
import React from 'react'
import { Theme } from '../../theme/theme'
import {
  WidgetLabelContainer,
  WidgetRemoveContainer,
} from '../widget-base-styles'
import {
  SingleDataPointWidgetContainer,
  SingleDataPointWidgetHeader,
  SingleDataPointWidgetTitle,
  SingleDataPointWidgetHeaderContainer,
  SingleDataPointWidgetIconColor,
  SingleDataPointWidgetIconPosition,
  PreviousSingleDataContainer,
  WidgetSingleDataPointTextOverrite,
} from './single-data-point-widget-styles'
import { DataPoint } from './build-single-data-point-widget'
import { formatDuration } from '../../lib/format-duration'
import { useAppSelector } from '../../hooks/redux-hooks'
import { WidgetKeys } from '../../lib/widgets/widgets-types'

type Props = {
  data: DataPoint
  title: string
  theme?: keyof Theme['widgets']
  icon?: string
  dataType: 'digit' | 'time'
  id: string
  type: WidgetKeys
  isActive: boolean
  onRemove?: () => void
}

export const SingleDataPointWidget = React.forwardRef<any, Props>(
  (
    {
      data,
      dataType,
      title,
      theme,
      icon,
      id,
      type,
      isActive,
      onRemove,
      children,
      ...props
    },
    ref
  ) => {
    const _showPreviousData = useAppSelector(
      (state) => state.settings.showPreviousStats
    )
    const editMode = useAppSelector((state) => state.config.editMode)
    const label = useAppSelector(
      (state) => state.settings.widgetSettings[id]?.blockLabel
    )
    const showPreviousData =
      _showPreviousData && type !== 'ticketUnsolvedWidget'

    return (
      <>
        <SingleDataPointWidgetContainer
          overrideTheme={theme}
          editMode={editMode}
          isActive={isActive}
          ref={ref}
          {...props}
        >
          {editMode && label && (
            <WidgetLabelContainer>
              <Typography type="BodySmallRegular">{label}</Typography>
            </WidgetLabelContainer>
          )}
          {onRemove && (
            <WidgetRemoveContainer>
              <IconButton size="small" onClick={onRemove} type="Secondary">
                close
              </IconButton>
            </WidgetRemoveContainer>
          )}

          {icon && (
            <SingleDataPointWidgetIconPosition>
              <Icon>
                <SingleDataPointWidgetIconColor overrideTheme={theme}>
                  {icon}
                </SingleDataPointWidgetIconColor>
              </Icon>
            </SingleDataPointWidgetIconPosition>
          )}
          <SingleDataPointWidgetHeaderContainer>
            <Typography
              type="Heading1"
              custom={
                dataType === 'digit'
                  ? {
                      lineHeight: 120,
                      size: 104,
                      style: 'normal',
                      weight: 'bold',
                    }
                  : undefined
              }
            >
              <SingleDataPointWidgetHeader
                overrideTheme={theme}
                showPreviousData={showPreviousData}
                type={dataType}
              >
                {dataType === 'time' ? formatDuration(data.live) : data.live}
              </SingleDataPointWidgetHeader>
            </Typography>
          </SingleDataPointWidgetHeaderContainer>
          {showPreviousData && dataType !== 'time' && (
            <PreviousSingleDataContainer overrideTheme={theme}>
              {data.previous === data.live ? (
                <Icon size="large">arrow_forward</Icon>
              ) : (
                <>
                  <Icon size="large">
                    {data.previous < data.live
                      ? 'arrow_upward'
                      : 'arrow_downward'}
                  </Icon>
                  <Typography type="Heading4">
                    <WidgetSingleDataPointTextOverrite overrideTheme={theme}>
                      {data.previous}
                    </WidgetSingleDataPointTextOverrite>
                  </Typography>
                </>
              )}
            </PreviousSingleDataContainer>
          )}
          <Typography type="BodyLargeSemiBold">
            <SingleDataPointWidgetTitle overrideTheme={theme}>
              {title}
            </SingleDataPointWidgetTitle>
          </Typography>
          {editMode && children}
        </SingleDataPointWidgetContainer>
      </>
    )
  }
)
