import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchJson } from '../fetch-json'
import { RootState } from '../root-reducer'

type ApiResponse = {
  code: string
}

export const generatePublicLinkAsync = createAsyncThunk<
  ApiResponse,
  void,
  { state: RootState }
>('general/generatePublicLink', async (_, { getState }) => {
  const queryString = new URL(window.location.href)
  const urlParams = new URLSearchParams(queryString.search)
  const secret = urlParams.get('secret')

  const response = await fetchJson(`/shorten?secret=${secret}`, {
    method: 'POST',
  })
  return response
})
