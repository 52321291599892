import React, { useRef, useEffect, useState } from 'react'
import * as Sentry from '@sentry/browser'
import { AutoScrollContainer } from './auto-scroll-styles'

type Props = {
  scrollBy?: number
  speed?: number
}

export const AutoScroll: React.FC<Props> = ({ children, scrollBy, speed }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [showScrollBar, setShowScrollBar] = useState<boolean>()
  const _scrollBy = scrollBy || 88
  const _speed = speed || 2500

  useEffect(() => {
    const interval = setInterval(() => {
      if (ref && ref.current) {
        if (
          ref.current?.scrollHeight -
            (ref.current?.scrollTop + ref.current?.clientHeight) >
          _scrollBy / 3
        ) {
          setScrollPosition(ref.current?.scrollTop + _scrollBy)
        } else {
          setScrollPosition(0)
        }
      }
    }, _speed)

    return () => clearInterval(interval)
  }, [_scrollBy])

  useEffect(() => {
    setTimeout(() => {
      if (ref && ref.current) {
        if (
          ref.current?.scrollHeight -
            (ref.current?.scrollTop + ref.current?.clientHeight) >
          _scrollBy / 3
        ) {
          setShowScrollBar(true)
        } else {
          setShowScrollBar(false)
        }
      }
    }, 2500)
  }, [])

  const scrollTable = () => {
    if (ref && ref.current) {
      // Try-catch is necessary since old TV browsers might not support scrollTo method
      try {
        ref.current.scrollTo({
          top: scrollPosition,
          behavior: 'smooth',
        })
      } catch (err) {
        console.error(err)
        Sentry.captureException(err)
      }
    }
  }

  useEffect(() => {
    scrollTable()
  }, [scrollPosition])

  return (
    <AutoScrollContainer ref={ref} showScrollBar={showScrollBar}>
      {children}
    </AutoScrollContainer>
  )
}
