import styled, { css } from 'styled-components'

export const boardMediaWidth: Array<[string, number]> = [
  ['(max-width: 1160px)', 0.72],
  ['(max-width: 1250px)', 0.74],
  ['(max-width: 1365px)', 0.76],
  ['(max-width: 1525px)', 0.78],
]

export const BoardContainer = styled.div<{
  editMode: boolean
}>`
  width: auto;
  grid-area: content;
  overflow: auto;
  transition: margin-left 0.2s ease-out 0s, margin-top 0.2s ease-out 0s,
    transform 0.3s ease-out 0s;
  box-sizing: border-box;
  height: 100vh;
  min-width: 100vw;
  background-color: ${(props) => props.theme.board.plate};

  ${(props) =>
    props.editMode
      ? css`
          margin-left: -150px;
          transform: scale(0.8);
          border: 1px dashed ${(props) => props.theme.color.neutral4};
          border-radius: 4px;

          @media ${boardMediaWidth[3][0]} {
            transform: scale(${boardMediaWidth[3][1]});
          }

          @media ${boardMediaWidth[2][0]} {
            transform: scale(${boardMediaWidth[2][1]});
          }

          @media ${boardMediaWidth[1][0]} {
            transform: scale(${boardMediaWidth[1][1]});
          }

          @media ${boardMediaWidth[0][0]} {
            transform: scale(${boardMediaWidth[0][1]});
          }
        `
      : css`
          margin-top: -150x;
          margin-left: 0px;
          @supports (display: grid) {
            margin-left: -300px;
            margin-top: -64px;
          }
        `}

  .layout {
    height: 100% !important;
  }

  .react-grid-item.react-grid-placeholder {
    background-color: ${(props) => props.theme.color.neutral3};
    border-radius: 8px;
  }
`

export const ImboxBranding = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: 10px;
`

export const ImboxLogo = styled.img`
  height: 12px;
  margin-left: 4px;
`
