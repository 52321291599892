import styled from 'styled-components'
import { Theme } from '../../theme/theme'
import { widgetBaseStyle } from '../widget-base-styles'

export const LabelWidgetContainer = styled.div<{
  overrideTheme?: keyof Theme['widgets']
  editMode?: boolean
  isActive: boolean
}>`
  ${widgetBaseStyle};
`

export const LabelWidgetInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`

export const LabelWidgetText = styled.div`
  text-align: center;
  font-size: 24px;
  color: ${(props) => props.theme.genericWidget.dataPoint};

  @media (max-height: 1000px) {
    font-size: 20px;
  }

  @media (max-width: 2000px) {
    font-size: 20px;
  }

  @media (max-width: 1700px) {
    font-size: 18px;
  }

  @media (max-width: 1400px) {
    font-size: 16px;
  }
`

export const LabelWidgetPlaceholder = styled(LabelWidgetText)`
  opacity: 0.5;
`
