import { Icon, Typography } from '@imbox/ui-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { draggingNewWidgetSlice } from '../../lib/features/dragging-new-widget/dragging-new-widget-slice'
import { Widget } from '../../lib/widgets/widgets-types'
import {
  SidbarListItem,
  SidebarListItemContent,
  IconLeftContainer,
  IconRightContainer,
} from './sidebar-styles'

type Props = {
  widget: Widget
  icon?: string
}

export const SidebarListItem: React.FC<Props> = ({ widget, icon }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  return (
    <SidbarListItem
      className="droppable-element"
      draggable={true}
      unselectable="on"
      data-grid={{ i: widget.i, w: widget.w, h: widget.h }}
      onDragEnd={() => {
        dispatch(
          draggingNewWidgetSlice.actions.setDraggingNewWidget({
            i: '',
            w: 0,
            h: 0,
          })
        )
      }}
      onDragStart={(event) => {
        dispatch(
          draggingNewWidgetSlice.actions.setDraggingNewWidget({
            i: Math.random().toString(),
            w: widget.w,
            h: widget.h,
            minW: widget.minW,
            minH: widget.minH,
            maxW: widget.maxW,
            maxH: widget.maxH,
            isResizable: widget.isResizable,
            type: widget.i,
          })
        )

        // this is a hack for firefox
        // Firefox requires some kind of initialization
        // which we can do by adding this attribute
        // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
        event.dataTransfer.setData('text/plain', '')

        event.dataTransfer.setData('i', widget.i)
      }}
    >
      <IconLeftContainer>
        <Icon size="small" color="neutral3">
          {icon}
        </Icon>
      </IconLeftContainer>
      <SidebarListItemContent>
        <Typography type="BodyMediumMedium">
          {t(widget.i as any, { returnObjects: true }).listItemTitle}
        </Typography>
        <Typography type="BodySmallRegular" color="neutral5">
          {t(widget.i as any, { returnObjects: true }).description}
        </Typography>
      </SidebarListItemContent>
      <IconRightContainer>
        <Icon size="small" color="neutral3">
          drag_indicator
        </Icon>
      </IconRightContainer>
    </SidbarListItem>
  )
}
