import React, { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../hooks/redux-hooks'
import { selectDataPointChatLabels } from '../../lib/features/data-points/data-points-slice'
import {
  WidgetLabelContainer,
  WidgetRemoveContainer,
} from '../widget-base-styles'
import { IconButton, Typography } from '@imbox/ui-components'
import {
  TableCellLarge,
  TableHeaderLarge,
  TableWidgetContainer,
  WidgetTableContainer,
} from '../table/table-styles'
import { AutoScroll } from '../auto-scroll/auto-scroll'
import { WidgetKeys } from '../../lib/widgets/widgets-types'

type Props = {
  id: string
  type: WidgetKeys
  isActive: boolean
  onRemove?: () => void
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export const ChatTableWidget = React.forwardRef<any, Props>(
  ({ id, type, isActive, onRemove, children, ...props }, ref) => {
    const { t } = useTranslation()
    const editMode = useAppSelector((state) => state.config.editMode)
    const label = useAppSelector(
      (state) => state.settings.widgetSettings[id]?.blockLabel
    )
    const labels = useAppSelector((state) =>
      selectDataPointChatLabels(state, id)
    )
    const tags = new Array(...labels?.tags) ?? []
    const itemRef = useRef<HTMLTableRowElement | null>(null)
    const [rowHeight, setRowHeight] = useState<number>()

    useEffect(() => {
      setRowHeight(itemRef.current?.clientHeight)
    }, [])

    tags.sort((a, b) => {
      if (a.count < b.count) {
        return -1
      }
      if (a.count > b.count) {
        return 1
      }

      return 0
    })

    return (
      <TableWidgetContainer
        editMode={editMode}
        isActive={isActive}
        ref={ref}
        {...props}
      >
        {editMode && label && (
          <WidgetLabelContainer>
            <Typography type="BodySmallRegular">{label}</Typography>
          </WidgetLabelContainer>
        )}
        {onRemove && (
          <WidgetRemoveContainer>
            <IconButton size="small" onClick={onRemove} type="Secondary">
              close
            </IconButton>
          </WidgetRemoveContainer>
        )}
        <AutoScroll scrollBy={rowHeight} speed={2500}>
          <WidgetTableContainer>
            <table>
              <thead>
                <tr>
                  <th>
                    <TableHeaderLarge>
                      {t('chatTableWidget.labelHeader')}
                    </TableHeaderLarge>
                  </th>
                  <th>
                    <TableHeaderLarge>
                      {t('chatTableWidget.chatsHeader')}
                    </TableHeaderLarge>
                  </th>
                </tr>
              </thead>
              <tbody>
                {labels.tags.map((tag, index) => {
                  const even = index % 2 === 0

                  return (
                    <tr key={index}>
                      <td>
                        <TableCellLarge
                          position="flex-start"
                          type="default"
                          highEmphasis={even}
                        >
                          {tag.tag}
                        </TableCellLarge>
                      </td>
                      <td>
                        <TableCellLarge
                          position="flex-start"
                          type="default"
                          highEmphasis={even}
                        >
                          {tag.count}
                        </TableCellLarge>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </WidgetTableContainer>
        </AutoScroll>
        {editMode && children}
      </TableWidgetContainer>
    )
  }
)
