import { Typography } from '@imbox/ui-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../hooks/redux-hooks'
import {
  GridEmptyContainer,
  GridEmptyContainerInner,
  GridEmptyTitleContainer,
  GridEmptyTitleOverrite,
  GridEmptyTextOverrite,
} from './grid-empty-styles'

export const GridEmpty: React.FC = () => {
  const { t } = useTranslation()
  const theme = useAppSelector((state) => state.theme)

  return (
    <GridEmptyContainer>
      <GridEmptyContainerInner>
        <img src={`/assets/wallboard-empty-state-${theme}.svg`} alt={''} />
        <GridEmptyTitleContainer>
          <Typography type="BodyLargeMedium">
            <GridEmptyTitleOverrite>
              {t('gridEmpty.title')}
            </GridEmptyTitleOverrite>
          </Typography>
        </GridEmptyTitleContainer>
        <Typography type="BodyMediumMedium">
          <GridEmptyTextOverrite>{t('gridEmpty.text')}</GridEmptyTextOverrite>
        </Typography>
      </GridEmptyContainerInner>
    </GridEmptyContainer>
  )
}
