import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type draggingNewWidgetState = {
  i: string
  w: number
  h: number
  minW?: number
  minH?: number
  maxW?: number
  maxH?: number
  isResizable?: boolean
  type?: string
}
export const draggingNewWidgetSliceInitialState: draggingNewWidgetState = {
  i: '',
  w: 0,
  h: 0,
}
// when react-grid-layout releases a new version with onDropDragOver included this will probably not be nessesary
export const draggingNewWidgetSlice = createSlice({
  name: 'draggingNewWidget',
  initialState: draggingNewWidgetSliceInitialState,
  reducers: {
    setDraggingNewWidget: (
      state,
      action: PayloadAction<draggingNewWidgetState>
    ) => {
      return action.payload
    },
  },
})
