import styled, { css } from 'styled-components'

export const widgetBaseStyle = css<{ editMode?: boolean; isActive?: boolean }>`
  box-sizing: border-box;
  user-select: none;
  box-shadow: ${(props) => props.theme.shadow.medium};
  padding: 40px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.genericWidget.plate};
  height: 100%;
  width: 100%;

  ${(props) =>
    props.editMode &&
    css`
      border: 2px solid ${(props) => props.theme.genericWidget.plate};
      &:hover {
        cursor: pointer;
        box-shadow: ${(props) => props.theme.shadow.large};
      }
    `}

  ${(props) =>
    props.isActive && props.editMode &&
    css`
      border: 2px solid ${props.theme.color.primary};
      box-shadow: ${(props) => props.theme.shadow.large};
    `}

  ${(props) =>
    !props.editMode &&
    css`
      overflow: hidden;
    `}
`

export const WidgetLabelContainer = styled.div`
  position: absolute;
  bottom: calc(100% + 2px);
  left: 8px;
  background: ${(props) => props.theme.color.neutral2};
  padding: 4px 8px;
  border-radius: 4px 4px 0 0;
  z-index: -1;

  div {
    text-transform: uppercase;
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    color: ${(props) => props.theme.color.neutral5};
  }
`

export const WidgetRemoveContainer = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 1;
`

export const WidgetDataPointTextOverrite = styled.div`
  color: ${(props) => props.theme.genericWidget.dataPoint};
`

export const WidgetTextOverrite = styled.div`
  color: ${(props) => props.theme.genericWidget.text};
`
