import { Widget } from './widgets-types'

export type PhoneWidgetKeys =
  | 'callsTotalCompletedWidget'
  | 'callsAvgTalkTimeWidget'
  | 'callsTotalMissedWidget'
  | 'callsTotalAbandonedWidget'
  | 'callsAvgWaitTimeWidget'
  | 'callQueuesWidget'
  | 'callsMaxWaitTimeWidget'
  | 'callsCurrentWorkloadWidget'
  | 'callsTimesWidget'
  | 'callsTotalAnsweredWidget'
  | 'callServiceLevelWidget'

export const phoneWidgets: {
  [key in PhoneWidgetKeys]: Widget
} = {
  callsTotalCompletedWidget: {
    i: 'callsTotalCompletedWidget',
    h: 1,
    w: 1,
    singleDataPointProps: {
      theme: 'callsCompleted',
      type: 'digit',
    },
  },
  callsTotalAbandonedWidget: {
    i: 'callsTotalAbandonedWidget',
    h: 1,
    w: 1,
    singleDataPointProps: {
      theme: 'callsOutgoing',
      type: 'digit',
    },
  },
  callsTotalMissedWidget: {
    i: 'callsTotalMissedWidget',
    h: 1,
    w: 1,
    singleDataPointProps: {
      theme: 'callsMissed',
      type: 'digit',
    },
  },
  callsTotalAnsweredWidget: {
    i: 'callsTotalAnsweredWidget',
    h: 1,
    w: 1,
    singleDataPointProps: {
      theme: 'callsIncoming',
      type: 'digit',
    },
  },
  callsAvgWaitTimeWidget: {
    i: 'callsAvgWaitTimeWidget',
    h: 1,
    w: 2,
    isResizable: false,
    singleDataPointProps: { type: 'time' },
  },
  callsAvgTalkTimeWidget: {
    i: 'callsAvgTalkTimeWidget',
    h: 1,
    w: 2,
    isResizable: false,
    singleDataPointProps: { type: 'time' },
  },
  callsMaxWaitTimeWidget: {
    i: 'callsMaxWaitTimeWidget',
    h: 1,
    w: 2,
    isResizable: false,
    singleDataPointProps: { type: 'time' },
  },
  callsTimesWidget: {
    i: 'callsTimesWidget',
    h: 2,
    w: 2,
  },
  callsCurrentWorkloadWidget: {
    i: 'callsCurrentWorkloadWidget',
    h: 2,
    w: 2,
  },
  callQueuesWidget: {
    i: 'callQueuesWidget',
    h: 2,
    w: 6,
    minW: 4,
    minH: 1,
    isResizable: true,
  },
  callServiceLevelWidget: {
    i: 'callServiceLevelWidget',
    h: 1,
    w: 1,
    minH: 1,
    minW: 1,
    isResizable: true,
  },
}
