import GridLayout from 'react-grid-layout'

export const predefinedTicketLayout: GridLayout.Layout[] = [
  {
    w: 2,
    h: 2,
    x: 0,
    y: 0,
    i: 'ticketUnsolvedWidget',
    maxW: 2,
    maxH: 2,
    isDraggable: false,
    isResizable: false,
  },
  {
    w: 1,
    h: 2,
    x: 0,
    y: 1,
    i: 'ticketIncomingWidget',
    maxW: 2,
    maxH: 2,
    isDraggable: false,
    isResizable: false,
  },
  {
    w: 1,
    h: 2,
    x: 1,
    y: 1,
    i: 'ticketServedWidget',
    maxW: 2,
    maxH: 2,
    isDraggable: false,
    isResizable: false,
  },
  {
    w: 4,
    h: 4,
    x: 2,
    y: 0,
    i: 'ticketByChannelWidget',
    minW: 3,
    minH: 3,
    isDraggable: false,
    isResizable: false,
  },
]
