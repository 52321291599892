// Coordinates from yr.no
export const cities: Record<
  string,
  { name: string; lat: string; lon: string }
> = {
  got: {
    name: 'Göteborg',
    lat: '57.70833',
    lon: '11.9',
  },
  sthlm: {
    name: 'Stockholm',
    lat: '59.32938',
    lon: '18.06871',
  },
  malmo: {
    name: 'Malmö',
    lat: '55.60587',
    lon: '13.00073',
  },
  uppsala: {
    name: 'Uppsala',
    lat: '59.85882',
    lon: '17.63889',
  },
  sollentuna: {
    name: 'Sollentuna',
    lat: '59.42804',
    lon: '17.95093',
  },
  vasteras: {
    name: 'Västerås',
    lat: '59.61617',
    lon: '16.55276',
  },
  orebro: {
    name: 'Örebro',
    lat: '59.27412',
    lon: '15.2066',
  },
  linkoping: {
    name: 'Linköping',
    lat: '58.41086',
    lon: '15.62157',
  },
  helsingborg: {
    name: 'Helsingborg',
    lat: '56.04673',
    lon: '12.69437',
  },
  jonkoping: {
    name: 'Jönköping',
    lat: '57.78145',
    lon: '14.15618',
  },
  norrkoping: {
    name: 'Norrköping',
    lat: '58.59419',
    lon: '16.1826',
  },
  lund: {
    name: 'Lund',
    lat: '55.70584',
    lon: '13.19321',
  },
  umea: {
    name: 'Umeå',
    lat: '63.82842',
    lon: '20.25972',
  },
  gavle: {
    name: 'Gävle',
    lat: '60.67452',
    lon: '17.14174 ',
  },
  sodertalje: {
    name: 'Södertälje',
    lat: '59.19554',
    lon: '17.62525',
  },
  boras: {
    name: 'Borås',
    lat: '57.72101',
    lon: '12.9401',
  },
  halmstad: {
    name: 'Halmstad',
    lat: '56.67446',
    lon: '12.85676',
  },
  vaxjo: {
    name: 'Växjö',
    lat: '56.87767',
    lon: '14.80906',
  },
  eskilstuna: {
    name: 'Eskilstuna',
    lat: '59.36661',
    lon: '16.5077',
  },
  karlstad: {
    name: 'Karlstad',
    lat: '59.3793',
    lon: '13.50357',
  },
}
