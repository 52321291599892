import { Widget, WidgetKeys } from '../../lib/widgets/widgets-types'
import { CallQueuesWidget } from '../call-queues-table-widget/call-queues-table-widget'
import { CallsCurrentWorkloadWidget } from '../call-current-workload-widget/call-current-workload-widget'
import { CallServiceLevelWidget } from '../call-sla-widget/call-sla-widget'
import { CallTimesWidget } from '../call-times-widget/call-times-widget'
import { ChatServiceLevelWidget } from '../chat-sla-widget/chat-sla-widget'
import { ChatCurrentWorkloadWidget } from '../chat-current-workload-widget/chat-current-workload-widget'
import { ChatTableWidget } from '../chat-table-widget/chat-table-widget'
import { ChatTimesWidget } from '../chat-times-widget/chat-times-widget'
import { BuildSingleDataPointWidget } from '../single-data-point-widget/build-single-data-point-widget'
import { TicketsByChannelWidget } from '../tickets-by-channel-widget/tickets-by-channel-widget'
import { WeatherWidget } from '../weather-widget/weather-widget'
import { LabelWidget } from '../label-widget/label-widget'

export const renderWidgets = (
  widget: Widget,
  editMode: boolean,
  isActive: boolean,
  onRemoveWidget: (id: string) => void,
  onClick: (id: string, type: WidgetKeys, e: React.MouseEvent<HTMLElement>) => void,
  id: string,
  type: string
): JSX.Element => {
  if (widget.singleDataPointProps) {
    return (
      <BuildSingleDataPointWidget
        key={id}
        id={id}
        widget={widget as any}
        isActive={isActive}
        onRemove={editMode ? () => onRemoveWidget(id) : undefined}
        onClick={editMode ? (e) => onClick(id, type as WidgetKeys, e) : undefined}
      />
    )
  }

  switch (type) {
    case 'callQueuesWidget':
      return (
        <CallQueuesWidget
          key={id}
          id={id}
          isActive={isActive}
          onRemove={editMode ? () => onRemoveWidget(id) : undefined}
          onClick={editMode ? (e) => onClick(id, type, e) : undefined}
        />
      )
    case 'callsCurrentWorkloadWidget':
      return (
        <CallsCurrentWorkloadWidget
          key={id}
          id={id}
          isActive={isActive} 
          onRemove={editMode ? () => onRemoveWidget(id) : undefined}
          onClick={editMode ? (e) => onClick(id, type, e) : undefined}
        />
      )
    case 'callsTimesWidget':
      return (
        <CallTimesWidget
          key={id}
          id={id}
          isActive={isActive}
          onRemove={editMode ? () => onRemoveWidget(id) : undefined}
          onClick={editMode ? (e) => onClick(id, type, e) : undefined}
        />
      )
    case 'chatTableWidget':
      return (
        <ChatTableWidget
          key={id}
          id={id}
          isActive={isActive}
          onRemove={editMode ? () => onRemoveWidget(id) : undefined}
          onClick={editMode ? (e) => onClick(id, type, e) : undefined}
          type={type}
        />
      )
    case 'chatsTimesWidget':
      return (
        <ChatTimesWidget
          key={id}
          id={id}
          isActive={isActive}
          onRemove={editMode ? () => onRemoveWidget(id) : undefined}
          onClick={editMode ? (e) => onClick(id, type, e) : undefined}
        />
      )
    case 'chatCurrentWorkloadWidget':
      return (
        <ChatCurrentWorkloadWidget
          key={id}
          id={id}
          isActive={isActive}
          onRemove={editMode ? () => onRemoveWidget(id) : undefined}
          onClick={editMode ? (e) => onClick(id, type, e) : undefined}
        />
      )
    case 'ticketByChannelWidget':
      return (
        <TicketsByChannelWidget
          key={id}
          id={id}
          isActive={isActive}
          onRemove={editMode ? () => onRemoveWidget(id) : undefined}
          onClick={editMode ? (e) => onClick(id, type, e) : undefined}
        />
      )
    case 'weatherWidget':
      return (
        <WeatherWidget
          key={id}
          id={id}
          isActive={isActive}
          onRemove={editMode ? () => onRemoveWidget(id) : undefined}
          onClick={editMode ? (e) => onClick(id, type, e) : undefined}
        />
      )
    case 'callServiceLevelWidget':
      return (
        <CallServiceLevelWidget
          key={id}
          id={id}
          isActive={isActive}
          onRemove={editMode ? () => onRemoveWidget(id) : undefined}
          onClick={editMode ? (e) => onClick(id, type, e) : undefined}
        />
      )
    case 'chatServiceLevelWidget':
      return (
        <ChatServiceLevelWidget
          key={id}
          id={id}
          isActive={isActive}
          onRemove={editMode ? () => onRemoveWidget(id) : undefined}
          onClick={editMode ? (e) => onClick(id, type, e) : undefined}
        />
      )
      case 'labelWidget':
        return (
          <LabelWidget
            key={id}
            id={id}
            isActive={isActive}
            onRemove={editMode ? () => onRemoveWidget(id) : undefined}
            onClick={editMode ? (e) => onClick(id, type, e) : undefined}
          />
        )
    default:
      break
  }

  return <div></div>
}
