import GridLayout from 'react-grid-layout'

export const predefinedPhoneLayout: GridLayout.Layout[] = [
  {
    w: 1,
    h: 1,
    x: 5,
    y: 1,
    i: 'callsTotalCompletedWidget',
    moved: false,
    static: false,
    isDraggable: false,
    isResizable: false,
  },
  {
    w: 1,
    h: 1,
    x: 4,
    y: 1,
    i: 'callsTotalAbandonedWidget',
    moved: false,
    static: false,
    isDraggable: false,
    isResizable: false,
  },
  {
    w: 1,
    h: 1,
    x: 4,
    y: 0,
    i: 'callsTotalMissedWidget',
    moved: false,
    static: false,
    isDraggable: false,
    isResizable: false,
  },
  {
    w: 1,
    h: 1,
    x: 5,
    y: 0,
    i: 'callsTotalAnsweredWidget',
    moved: false,
    static: false,
    isDraggable: false,
    isResizable: false,
  },
  {
    w: 2,
    h: 2,
    x: 0,
    y: 0,
    i: 'callsCurrentWorkloadWidget',
    moved: false,
    static: false,
    isDraggable: false,
  },
  {
    w: 2,
    h: 2,
    x: 2,
    y: 0,
    i: 'callsTimesWidget',
    moved: false,
    static: false,
    isDraggable: false,
    isResizable: false,
  },
  {
    w: 6,
    h: 3,
    x: 0,
    y: 2,
    i: 'callQueuesWidget',
    minW: 4,
    minH: 1,
    moved: false,
    static: false,
    isDraggable: false,
    isResizable: false,
  },
]
