import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import React from 'react'
import { TopLevelErrorBoundary } from './components/top-level-error-boundary'
import { ThemeController } from './components/theme-controller'
import { store } from './lib/store'
import { Provider } from 'react-redux'
import { App } from './App'
import './lib/window-refresh'

import './index.css'
import 'bootstrap/dist/css/bootstrap.css'
import './i18n/config'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeController>
        <TopLevelErrorBoundary>
          <App />
        </TopLevelErrorBoundary>
      </ThemeController>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// When iframed we register an eventlistener to allow parent window to request the getQuerystring
try {
  if (window.location !== window.parent.location) {
    window.addEventListener('message', (ev: MessageEvent) => {
      if (ev.data?.type === 'getQuerystring') {
        window.parent.postMessage(
          {
            type: 'getQuerystring.response',
            payload: window.location.search,
          },
          '*'
        )
      }
    })
  }
} catch (err) {
  // no-op
}
