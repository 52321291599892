import GridLayout from 'react-grid-layout'

export const predefinedChatLayout: GridLayout.Layout[] = [
  {
    w: 1,
    h: 1,
    x: 4,
    y: 0,
    i: 'chatsMissedWidget',
    maxW: 2,
    maxH: 2,
    isResizable: false,
  },
  {
    w: 2,
    h: 1,
    x: 4,
    y: 1,
    i: 'chatsTotalWidget',
    maxW: 2,
    maxH: 2,
    isResizable: false,
  },
  {
    w: 1,
    h: 1,
    x: 5,
    y: 0,
    i: 'chatsAnsweredWidget',
    maxW: 2,
    maxH: 2,
    isResizable: false,
  },
  {
    w: 2,
    h: 2,
    x: 2,
    y: 0,
    i: 'chatsTimesWidget',
    isResizable: false,
  },
  {
    w: 2,
    h: 2,
    x: 0,
    y: 0,
    i: 'chatCurrentWorkloadWidget',
    isResizable: false,
  },
  {
    w: 6,
    h: 3,
    x: 0,
    y: 2,
    i: 'chatTableWidget',
    minW: 2,
    minH: 1,
    isResizable: false,
  },
]
