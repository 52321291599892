import { Theme } from './theme'
import { uiComponentsTheme } from '@imbox/ui-components'

export const lightTheme: Theme = {
  ...uiComponentsTheme,

  board: {
    plate: '#F4F5F7',
  },

  genericWidget: {
    plate: uiComponentsTheme.color.white,
    dataPoint: uiComponentsTheme.color.neutral6,
    text: uiComponentsTheme.color.neutral5,
    divider: uiComponentsTheme.color.neutral2,
  },

  widgets: {
    callsCompleted: {
      singleDataPointWidgetTheme: {
        dataPointColor: uiComponentsTheme.color.white,
        title: uiComponentsTheme.color.white,
        plate: uiComponentsTheme.color.info,
      },
    },

    callsMissed: {
      singleDataPointWidgetTheme: {
        dataPointColor: uiComponentsTheme.color.white,
        title: uiComponentsTheme.color.white,
        plate: uiComponentsTheme.color.danger,
      },
    },

    callsOutgoing: {
      singleDataPointWidgetTheme: {
        dataPointColor: uiComponentsTheme.color.white,
        title: uiComponentsTheme.color.white,
        plate: uiComponentsTheme.color.warning,
      },
    },

    callsIncoming: {
      singleDataPointWidgetTheme: {
        dataPointColor: uiComponentsTheme.color.white,
        title: uiComponentsTheme.color.white,
        plate: uiComponentsTheme.color.success,
      },
    },

    callQueues: {
      headerText: uiComponentsTheme.color.neutral5,
    },

    chatsMissedWidget: {
      singleDataPointWidgetTheme: {
        dataPointColor: uiComponentsTheme.color.white,
        title: uiComponentsTheme.color.white,
        plate: uiComponentsTheme.color.danger,
      },
    },
    chatsTotalWidget: {
      singleDataPointWidgetTheme: {
        dataPointColor: uiComponentsTheme.color.white,
        title: uiComponentsTheme.color.white,
        plate: uiComponentsTheme.color.info,
      },
    },
    chatsAnsweredWidget: {
      singleDataPointWidgetTheme: {
        dataPointColor: uiComponentsTheme.color.white,
        title: uiComponentsTheme.color.white,
        plate: uiComponentsTheme.color.success,
      },
    },
    serviceLevelWidget: {
      previousDataSuccess: uiComponentsTheme.color.success,
      previousDataDanger: uiComponentsTheme.color.danger,
    }
  },

  baseWidgets: {
    singleDataPointWidget: {
      dataPointColor: uiComponentsTheme.color.neutral6,
      title: uiComponentsTheme.color.neutral5,
    },
  },

  general: {
    title: uiComponentsTheme.color.neutral5,
    text: uiComponentsTheme.color.neutral4
  }
}
