import React from 'react'
import styled, { css } from 'styled-components'
import { Button as _Button, Icon } from '@imbox/ui-components'

const StyledButton = styled.button<{ isActive?: boolean; icon?: string }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.shadow.small};
  width: 100%;
  height: 40px;
  border: 1px solid ${(props) => props.theme.color.neutral2};
  background: ${(props) => props.theme.color.white};

  ${(props) =>
    props.icon
      ? css``
      : css`
          justify-content: center;
          div {
            color: ${props.theme.color.neutral5};
          }
        `}

  ${(props) =>
    props.isActive &&
    css`
      background: ${props.theme.color.neutral1};
    `}

    
  &:hover {
    border: 1px solid ${(props) => props.theme.color.neutral3};
    background: ${(props) => props.theme.color.neutral1};
  }

  .material-icons {
    color: ${(props) => props.theme.color.neutral3};
  }

  .icon-left {
    margin-right: 8px;
  }

  .icon-right {
    position: absolute;
    right: 16px;
  }
`

type Props = {
  icon?: string
  isActive?: boolean
  onClick: () => void
}

export const Button: React.FC<Props> = ({
  icon,
  isActive,
  onClick,
  children,
}) => {
  return (
    <StyledButton onClick={onClick} isActive={isActive} icon={icon}>
      {icon && (
        <Icon size="small" className="icon-left">
          {icon}
        </Icon>
      )}
      {children}
      {icon && (
        <Icon size="small" className="icon-right">
          add
        </Icon>
      )}
    </StyledButton>
  )
}
