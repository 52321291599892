import React, { Suspense, useEffect } from 'react'
import styled from 'styled-components'
import { getDataPointsAsync } from './lib/features/data-points/data-points-api'
import { store } from './lib/store'
import { Board } from './components/board/board'
import { useAppDispatch } from './hooks/redux-hooks'
import { TopBar } from './components/top-bar/top-bar'
import { Sidebar } from './components/sidebar/sidebar'
import {
  getPageFiltersAsync,
  getTicketCategoriesAsync,
  getGroupsAsync,
} from './lib/features/settings/settings-api'
import { getStoredDataAsync } from './lib/features/data-store/data-store-api'
import { PropertyPanel } from './components/property-panel/property-panel'
import { windowRefresh } from './lib/window-refresh'

const OneMinutes = 60000

setInterval(() => {
  store.dispatch(getDataPointsAsync())
}, OneMinutes)

windowRefresh()

const Container = styled.div`
  overflow: hidden;

  @supports (display: grid) {
    height: 100vh;
    display: grid;
    grid-template-areas:
      'header header'
      'sidebar content';
    grid-template-columns: 300px 1fr;
    grid-template-rows: 64px 1fr;
  }
  background-color: ${(props) => props.theme.color.neutral1};
`

export const App: React.FC = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getStoredDataAsync()).then(() => {
      dispatch(getDataPointsAsync())
      dispatch(getPageFiltersAsync())
      dispatch(getTicketCategoriesAsync())
      dispatch(getGroupsAsync())
    })
  }, [])

  return (
    <Suspense fallback={<div>...</div>}>
      <Container>
        <TopBar />
        <Sidebar />
        <PropertyPanel />
        <Board />
      </Container>
    </Suspense>
  )
}
