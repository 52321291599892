import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WidgetKeys } from '../../widgets/widgets-types'

type PropertyPanel = {
  isOpen: boolean
  id: string
  type?: WidgetKeys
}

export const propertyPanelSliceInitialState: PropertyPanel = {
  isOpen: false,
  id: '',
  type: undefined,
}

export const propertyPanelSlice = createSlice({
  name: 'propertyPanel',
  initialState: propertyPanelSliceInitialState,
  reducers: {
    closePropertyPanel: (state) => {
      state.isOpen = false
      state.id = ''
      state.type = undefined
    },
    openPropertyPanel: (
      state,
      action: PayloadAction<{ id: string; type?: WidgetKeys }>
    ) => {
      state.isOpen = true
      state.id = action.payload.id
      state.type = action.payload.type
    },
  },
})

export const { closePropertyPanel, openPropertyPanel } =
  propertyPanelSlice.actions
